import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import TopBar from '../../components/internal/topbar/TopBar.js';
import SideBar from '../../components/internal/sidebar/SideBar.js';
import './Internal.css';

// Investor Pages
import InvestorDashboard from '../../components/internal/body/investor/dashboard/Dashboard.js';
import InvestorContracts from '../../components/internal/body/investor/contracts/Contracts.js';
import ContractDetails from '../../components/internal/body/investor/contracts/details/ContractDetails.js';

// Sponsor Pages
import SponsorDashboard from '../../components/internal/body/sponsor/dashboard/Dashboard.js';
import SponsorPortfolio from '../../components/internal/body/sponsor/portfolio/Portfolio.js';
import SponsorInvestments from '../../components/internal/body/sponsor/investments/Investments.js';
import SponsorImpact from '../../components/internal/body/sponsor/impact/Impact.js';

// Shared Pages
import Funds from '../../components/internal/body/shared/funds/Funds.js';
import News from '../../components/internal/body/shared/news/News.js';
import Support from '../../components/internal/body/shared/support/Support.js';
import Chat from '../../components/internal/body/shared/chat/Chat.js';
import Settings from '../../components/internal/body/shared/settings/Settings.js';

// Redux + Firebase
import { subscribeToUserData, subscribeToContractsOverview } from '../../firebase/services/basicUserDataService.js';
import { useDispatch } from 'react-redux';
import { setUser } from '../../redox/actions/userActions.js';
import { setContractsOverview } from '../../redox/actions/overviewActions.js';


const Internal = () => {
    const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
    const [activeButton, setActiveButton] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const [accountType, setAccountType] = useState(null);
    const dispatch = useDispatch();

    const handleButtonClick = (label) => {
        setActiveButton(label);
    };

    // USER TOPBAR DATA
    useEffect(() => {
        // Set up the subscription to user data
        const unsubscribe = subscribeToUserData((userData) => {
            if (userData && userData.account_type) {
                setAccountType(userData.account_type);
                dispatch(setUser(userData));
            }
        });

        // Clean up the subscription when the component is unmounted
        return () => unsubscribe();
    }, [dispatch]);

    // USER OVERVIEW DATA
    useEffect(() => {
        const unsubscribe = subscribeToContractsOverview((overview) => {
            dispatch(setContractsOverview(overview));
        });

        return () => unsubscribe();
    }, [dispatch]);

    useEffect(() => {
        // Only run this effect if accountType has been set
        if (accountType === null) return;

        const currentPath = location.pathname;
        const basePath = currentPath.split('/')[1];

        // Determine pages to show in sidebar and for navigation abilities
        const investorPages = ['/dashboard', '/contracts', '/funds', '/news', '/support', '/chat', '/settings'];
        const sponsorPages = ['/dashboard', '/portfolio', '/investments', '/impact', '/contracts', '/funds', '/news', '/support', '/chat', '/settings'];
        const availablePages = accountType === "investor" ? investorPages : sponsorPages;

        const isPathValid = availablePages.some((page) => currentPath.startsWith(page));

        if (!isPathValid) {
            navigate('/dashboard');
            setActiveButton('Dashboard');
        } else {
            switch (basePath) {
                case "dashboard":
                    setActiveButton('Dashboard');
                    break;
                case "portfolio":
                    setActiveButton('Portfolio');
                    break;
                case "investments":
                    setActiveButton('Investments');
                    break;
                case "impact":
                    setActiveButton('Impact');
                    break;
                case "contracts":
                    setActiveButton('Contracts');
                    break;
                case "funds":
                    setActiveButton('Funds');
                    break;
                case "news":
                    setActiveButton('News & Updates');
                    break;
                case "support":
                    setActiveButton('Support');
                    break;
                case "chat":
                    setActiveButton('Live Chat');
                    break;
                case "settings":
                    setActiveButton('Settings');
                    break;
                default:
                    setActiveButton('Dashboard');
            }
        }
    }, [accountType, navigate, location]);

    return (
        <div className={`internal-app ${sidebarCollapsed ? 'sidebar-collapsed' : ''}`}>
            <TopBar
                onSidebarToggle={() => {
                    setSidebarCollapsed(!sidebarCollapsed);
                }}
                collapsed={sidebarCollapsed}
            />
            <div className="internal-body">
                <SideBar collapsed={sidebarCollapsed} activeButton={activeButton} onClick={handleButtonClick} accountType={accountType} />
                <Routes>
                    {accountType === "investor" ? InvestorPages() : SponsorPages()}
                </Routes>
            </div>
        </div>
    );
};

const SponsorPages = () => {
    return (
        <>
            <Route path="/dashboard" element={<SponsorDashboard />} />
            <Route path="/portfolio" element={<SponsorPortfolio />} />
            <Route path="/investments" element={<SponsorInvestments />} />
            <Route path="/impact" element={<SponsorImpact />} />
            <Route path="/funds" element={<Funds />} />
            <Route path="/news" element={<News />} />
            <Route path="/support" element={<Support />} />
            <Route path="/chat" element={<Chat />} />
            <Route path="/settings" element={<Settings />} />
        </>
    );
};

const InvestorPages = () => {
    return (
        <>
            <Route path="/dashboard" element={<InvestorDashboard />} />
            <Route path="/contracts" element={<InvestorContracts />} />
            <Route path="/contracts/:contractId" element={<ContractDetails />} />
            <Route path="/funds" element={<Funds />} />
            <Route path="/news" element={<News />} />
            <Route path="/support" element={<Support />} />
            <Route path="/chat" element={<Chat />} />
            <Route path="/settings" element={<Settings />} />
        </>
    );
};

export default Internal;
