import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './main.module.css';

const Main = () => {
    const navigate = useNavigate();

    const navigateToPath = (pathNumber) => {
        if (pathNumber === 1) {
            navigate(`/getstarted/alt/thebasics`);
        } else {
            navigate(`/getstarted/inv/thebasics`);
        }
    };

    return (
        <div className={styles['main-container']}>
            <div className={styles['page-header-text']}>
                <p className={styles['page-header-tiny-text']}>Getting Started</p>
                <h2 className={styles['page-header-descriptor-text']}>Choose Account Type</h2>
            </div>
            <div className={styles['cards-container']}>
                <div className={styles['card']}>
                    <div className={styles['shield-icon']}>
                        <img src="/icons/getstarted/shield-icon.svg" alt="Shield Icon" />
                    </div>
                    <p className={styles['card-description']}>
                        Safeguard your investments with Tranquil’s simple and reliable protection
                    </p>
                    <button className={styles['story-button']} onClick={() => navigateToPath(2)}>
                        Investor
                    </button>
                </div>
                <div className={styles['card']}>
                    <div className={styles['chart-icon']}>
                        <img src="/icons/getstarted/chart-icon.svg" alt="Chart Line Icon" />
                    </div>
                    <p className={styles['card-description']}>
                        Invest for good to accelerate economic growth and unlock opportunities
                    </p>
                    <button className={styles['story-button']} onClick={() => navigateToPath(1)}>
                        Altruvestor
                    </button>
                </div>
            </div>
            <div>
                <p> </p>
                <h2> </h2>
            </div>
        </div>
    );
};

export default Main;
