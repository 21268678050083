import React from 'react';
import styles from './Contracts.module.css';
import Segment from '../../segment/Segment';
import Table from '../../../../shared/internal/table/Table';
import { useSelector } from 'react-redux';
import useSubscribeToAllContracts from '../../../../../firebase/services/useSubscribeToAllContracts.js';
import { useNavigate } from 'react-router-dom';

const InvestorContracts = () => {
    const headers = ['Code', 'Project Name', 'Coverage Amount', 'Residual Term', 'Status'];
    const types = ['numbered', 'normal', 'normal', 'normal', 'coloured'];
    const columnWidths = ['1', '1', '1', '1', '1'];
    const navigate = useNavigate();

    // Retrieve the contracts from the Redux store
    const contracts = useSelector((state) => state.contracts);

    // Call the custom hook to subscribe to all contracts
    useSubscribeToAllContracts();

    // Map data for pending and active contracts
    const pendingData = mapContractData(contracts.pending);
    const activeData = mapContractData(contracts.active);

    const handleRowClick = (idIndex, contractType) => {
        const contractId = contractType === 'pending' ? contracts.pending[idIndex].id : contracts.active[idIndex].id;
        navigate(`/contracts/${contractId}`);
    };

    return (
        <div className={styles["body"]}>
            <div className={styles["page-header"]}>Contracts</div>
            <div className={styles["page-segments"]}>
                <Segment title="Pending Contracts" segmentBody={<Table headers={headers} types={types} data={pendingData} columnWidths={columnWidths} emptyMessage={"You have no pending contracts..."} onRowClick={(idIndex) => handleRowClick(idIndex, 'pending')}></Table>}></Segment>
                <Segment title="Active Contracts" segmentBody={<Table headers={headers} types={types} data={activeData} columnWidths={columnWidths} emptyMessage={"You have no active contracts..."} onRowClick={(idIndex) => handleRowClick(idIndex, 'active')}></Table>}></Segment>
            </div>
        </div>
    );
};

// Helper function to map contract data
const mapContractData = (contracts) => {
    return contracts.map((contract) => {
        return [
            contract.code,
            contract.project_name,
            `$${Math.round(contract.coverage / 100 * contract.net_value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
            `${contract.residual_term} Days`,
            contract.status_message,
        ];
    });
};

export default InvestorContracts;
