import React, { useState, useEffect } from 'react';
import styles from './RadioButtons.module.css';

const RadioButton = ({ list, direction, selectedOption, onChange }) => {
    const [selectedItem, setSelectedItem] = useState(selectedOption);

    useEffect(() => {
        setSelectedItem(selectedOption);
    }, [selectedOption]);

    const handleSelect = (item) => {
        setSelectedItem(item);
        onChange(item);
    };

    return (
        <div className={`${styles.radioButtonContainer} ${direction === 'horizontal' ? styles.horizontal : styles.vertical}`}>
            {list.map((item) => (
                <label key={item} className={styles.radioButton}>
                    <input
                        type="radio"
                        id={item}
                        value={item}
                        checked={selectedItem === item}
                        onChange={() => handleSelect(item)}
                    />
                    {item}
                </label>
            ))}
        </div>
    );
};

export default RadioButton;
