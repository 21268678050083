import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import styles from './Selectables.module.css';

const Selectables = ({ options, onToggleOption }) => {
    return (
        <div className={styles['selectables-container']}>
            {options.map((option, index) => (
                <button
                    key={index}
                    className={`${styles['selectable-button']} ${option.selected ? styles['selected'] : ''}`}
                    onClick={() => onToggleOption(index)}
                >
                    {option.text}
                    {option.selected && (
                        <FontAwesomeIcon icon={faCheckCircle} className={styles['check-icon']} />
                    )}
                </button>
            ))}
        </div>
    );
};

export default Selectables;