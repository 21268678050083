import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import sharedStyles from './path1.module.css'; // Import shared styles
import styles from './page2.module.css'; // Import page-specific styles
import { FormContext } from '../FormContext.js';
import Selectables from '../../../../shared/selectables/Selectables';

const Path1Page2 = () => {
    const navigate = useNavigate();
    const { formData, setFormData } = useContext(FormContext);
    const [interests, setInterests] = useState(formData.interests);

    const handleToggleOption = (index) => {
        const updatedInterests = interests.map((interest, i) => {
            if (i === index) {
                return { ...interest, selected: !interest.selected };
            } else {
                return interest;
            }
        });
        setInterests(updatedInterests);
    };

    const isFormValid = interests.some((interest) => interest.selected);

    const handleNext = () => {
        if (isFormValid) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                interests,
            }));
            navigate('/getstarted/alt/accountdetails');
        }
    };

    const handleBack = () => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            interests,
        }));
        navigate('/getstarted/alt/thebasics');
    };

    return (
        <div className={sharedStyles['main-container']}>
            <div className={sharedStyles['page-header-text']}>
                <p className={sharedStyles['page-header-tiny-text']}>Quick Start</p>
                <h2 className={sharedStyles['page-header-descriptor-text']}>Investment Interests</h2>
            </div>
            <div className={styles['selectables-sizer']}>
                <Selectables options={interests} onToggleOption={handleToggleOption} />
            </div>
            <div className={sharedStyles['button-group']}>
                <button className={`${sharedStyles['next-button']} ${isFormValid ? '' : sharedStyles['disabled']}`} onClick={handleNext} disabled={!isFormValid}>
                    <strong>Next</strong>
                </button>
                <button className={sharedStyles['back-button']} onClick={handleBack}>
                    <FontAwesomeIcon icon={faChevronLeft} className={sharedStyles['chevron-icon']} />
                    <strong>Back</strong>
                </button>
            </div>
        </div>
    );
};

export default Path1Page2;
