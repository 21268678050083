import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import sharedStyles from './path1.module.css';
import styles from './page3.module.css';
import TextField from '../../../../shared/textfield/Textfield.js';
import Checkboxes from '../../../../shared/checkbox/Checkboxes.js';
import { FormContext } from '../FormContext.js';
import { signup } from '../../../../../firebase/services/accountServices.js';

const Path1Page3 = () => {
    const navigate = useNavigate();
    const { formData, setFormData } = useContext(FormContext);
    const [email, setEmail] = useState(formData.email || '');
    const [password, setPassword] = useState(formData.password || '');
    const [areCheckboxesChecked, setAreCheckboxesChecked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const checkboxList = [
        {
            text: 'I confirm that I am over 18 years old.',
        },
        {
            text: 'I have read the <a href="/getstarted/alt/privacy-policy" target="_blank">privacy policy</a> and <a href="/getstarted/alt/terms-and-conditions" target="_blank">terms and conditions</a>.',
        },
    ];

    useEffect(() => {
        const isFormValid =
            isEmailValid(email) === null && isPasswordValid(password) === null && areCheckboxesChecked;

        // Enable/disable the "Next" button
        const nextButton = document.querySelector('.next-button');
        if (nextButton) {
            nextButton.disabled = !isFormValid;
        }
    }, [email, password, areCheckboxesChecked]);

    const isEmailValid = (value) => {
        if (value.trim() === '') {
            return 'Email cannot be empty';
        }
        // Regular expression for email validation
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(value)) {
            return 'Invalid email address';
        }
        return null; // Return null when the email is valid
    };

    const isPasswordValid = (value) => {
        if (value.trim() === '') {
            return 'Password cannot be empty';
        }

        if (value.length < 8) {
            return 'Password must be at least 8 characters';
        }

        const hasNumber = /\d/.test(value);

        if (!hasNumber) {
            return 'Password must contain a number';
        }

        return null; // Return null when the password is valid
    };

    const isFormValid = () => {
        return (
            isEmailValid(email) === null &&
            isPasswordValid(password) === null &&
            email.trim() !== '' &&
            password.trim() !== '' &&
            areCheckboxesChecked
        );
    };

    const handleNext = () => {
        if (isFormValid()) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                email,
                password,
            }));
            handleSignUp();
        }
    };

    const handleBack = () => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            email,
            password,
        }));
        navigate('/getstarted/alt/investmentinterests');
    };

    const handleSignUp = async () => {
        console.log(email);
        console.log(password);

        setIsLoading(true);

        const result = await signup(email, password, "sponsor", formData);

        if (result.success) {
            console.log('SUCCESS');
            const user = result.user;
            console.log(user);
            navigate('/');
        } else {
            console.log('ERROR');
            const errorMessage = result.message;
            console.log(errorMessage);
        }

        setIsLoading(false);
    };

    return (
        <div className={sharedStyles['main-container']}>
            <div className={sharedStyles['page-header-text']}>
                <p className={sharedStyles['page-header-tiny-text']}>Quick Start</p>
                <h2 className={sharedStyles['page-header-descriptor-text']}>
                    Enter Your Email Address to Get Started
                </h2>
            </div>
            <div className={styles['body']}>
                <div className={styles['text-fields-container']}>
                    <div className={styles['text-field-sizer']}>
                        <TextField
                            defaultLabel="Enter your email"
                            focusLabel="EMAIL ADDRESS"
                            value={email}
                            onChange={setEmail}
                            validation={(value) => isEmailValid(value)}
                            showErrors={true}
                            onInputChange={() => { }}
                        />
                    </div>
                    <div className={styles['text-field-sizer']}>
                        <TextField
                            defaultLabel="Create a password"
                            focusLabel="PASSWORD"
                            value={password}
                            onChange={setPassword}
                            validation={(value) => isPasswordValid(value)}
                            showErrors={true}
                            onInputChange={() => { }}
                            obscureText={true}
                        />
                    </div>
                </div>
                <div className={styles['checkboxes']}>
                    <Checkboxes list={checkboxList} onChange={(isChecked) => setAreCheckboxesChecked(isChecked)} />
                </div>
            </div>

            <div className={sharedStyles['button-group']}>
                {isLoading ? (
                    <div className={styles['loading-container']}>
                        <div className={styles['loading-indicator']} />
                    </div>
                ) : (
                    <>
                        <button className={sharedStyles['next-button']} onClick={handleNext} disabled={!isFormValid()}>
                            <strong>Sign up</strong>
                        </button>
                        {!isLoading && (
                            <button className={sharedStyles['back-button']} onClick={handleBack}>
                                <FontAwesomeIcon icon={faChevronLeft} className={sharedStyles['chevron-icon']} />
                                <strong>Back</strong>
                            </button>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default Path1Page3;
