// firebase.js
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';


// Config
const firebaseConfig = {
    apiKey: "AIzaSyBzC0P4rxLJ5bi4usZrdV9ptVI5Z6XPiRo",
    authDomain: "tranquil-capital.firebaseapp.com",
    projectId: "tranquil-capital",
    storageBucket: "tranquil-capital.appspot.com",
    messagingSenderId: "992082436901",
    appId: "1:992082436901:web:b3d3e5fb70ca22f055b0cc",
    measurementId: "G-4BFLVQYYPT"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

export default app;