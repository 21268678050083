import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons'; // Importing the "X" icon
import styles from './TopBar.module.css';

function TopBar() {
    const closeWindow = () => {
        window.close();
    };

    return (
        <div className={styles['top-bar']}>
            <BrandText />
            <div className={styles['close-button']} onClick={closeWindow}>
                <FontAwesomeIcon icon={faTimes} />
            </div>
        </div>
    );
}

function BrandText() {
    const navigate = useNavigate();

    const handleClickBrand = () => {
        // Navigate back to the launch page
        navigate("/");
    };

    return (
        <div className={styles.brand} onClick={handleClickBrand}>
            <img src={"/images/logo_horizontal.png"} alt="Tranquil Capital" />
        </div>
    );
}

export default TopBar;