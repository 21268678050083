import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import styles from './TopBar.module.css';

function TopBar({ currentIndex, totalPaths }) {
    return (
        <div className={styles['top-bar']}>
            <BrandText />
            <div className={styles['progress-bar-sizer']}>
                {currentIndex >= 0 && (
                    <ProgressBar currentIndex={currentIndex} totalPaths={totalPaths} />
                )}
            </div>
            <div className={styles['login-signup']}>
                {currentIndex === -1 ? (
                    <React.Fragment>
                        <QuestionText />
                        <SigninButton />
                    </React.Fragment>
                ) : null}
            </div>
        </div>
    );
}

function BrandText() {
    const navigate = useNavigate();

    const handleClickBrand = () => {
        navigate('/');
    };

    return (
        <div className={styles['brand']} onClick={handleClickBrand}>
            <img src={"/images/logo_horizontal.png"} alt="Tranquil Capital" />
            {/* Tranquil Capital */}
        </div>
    );
}

function QuestionText() {
    return <button className={styles['question-text']}>Existing User?</button>;
}

function SigninButton() {
    const navigate = useNavigate();

    const handleClickSignin = () => {
        navigate('/signin');
    };

    return (
        <button className={styles['signup-button']} onClick={handleClickSignin}>
            Sign in <FontAwesomeIcon icon={faChevronRight} className={styles['signup-chevron']} />
        </button>
    );
}

function ProgressBar({ currentIndex, totalPaths }) {
    const circles = Array.from({ length: totalPaths }, (_, index) => (
        <ProgressCircle key={index} isActive={index <= currentIndex} />
    ));

    return <div className={styles['progress-bar']}>{circles}</div>;
}

function ProgressCircle({ isActive }) {
    const circleClasses = `${styles['progress-circle']} ${isActive ? styles.active : ''}`;
    return <div className={circleClasses}></div>;
}

export default TopBar;