import { auth, db } from '../configs.js';
import { doc, onSnapshot } from 'firebase/firestore';

export const subscribeToUserData = (onUserDataChange) => {
    const currentUser = auth.currentUser;
    if (currentUser) {
        const userRef = doc(db, 'users', currentUser.uid);
        return onSnapshot(userRef, (snapshot) => {
            if (snapshot.exists()) {
                const userData = snapshot.data();
                userData.date_created = userData.date_created.toDate().toISOString();
                onUserDataChange(userData);
            }
        });
    }
};

export const subscribeToContractsOverview = (callback) => {
    const currentUser = auth.currentUser;
    const userRef = doc(db, 'users', currentUser.uid);

    const unsubscribe = onSnapshot(userRef, (snapshot) => {
        const userData = snapshot.data();
        callback(userData ? userData.contracts_overview : null);
    });

    return unsubscribe;
};