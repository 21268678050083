import { LOGOUT } from '../actions/authActions';

const initialState = {
    first_name: '',
    last_name: '',
    organisation: '',
    account_type: '',
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGOUT:
            return initialState;
        case 'SET_USER':
            const { first_name, last_name, organisation, account_type } = action.payload;
            return {
                ...state,
                first_name,
                last_name,
                organisation,
                account_type,
            };
        default:
            return state;
    }
};

export default userReducer;