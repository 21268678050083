import React, { useState } from 'react';
import styles from './OurClients.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const Title = () => (
    <div className={styles['section-title']}>OUR CLIENTS</div>
);

const LogosGallery = () => {
    const logos = ['logo_1', 'logo_2', 'logo_3', 'logo_4', 'logo_5', 'logo_6', 'logo_7', 'logo_10', 'logo_9', 'logo_8', 'logo_11'];

    return (
        <div className={styles['logo-container']}>
            {logos.map((logo, index) => (
                <div key={index} className={styles['logo-item']}>
                    <img src={`${process.env.PUBLIC_URL}/logos/${logo}.png`} alt={logo} style={{ height: '100px' }} />
                </div>
            ))}
        </div>
    );
};

const Testimonial = ({ author, company, description, buttonText }) => (
    <div className={styles.testimonial}>
        <div className={styles['testimonial-text']}>
            <div className={styles['testimonial-top']}>
                <div className={styles['testimonial-author']}>{author}</div>
                <div className={styles['testimonial-company']}>{company}</div>
            </div>
            <div className={styles['testimonial-description']}>{description}</div>
        </div>
        <button className={styles['story-button']}>{buttonText}</button>
    </div>
);

const Testimonials = () => {
    const testimonials = [
        { author: 'Author', company: 'Company', description: 'Tranquil’s innovative use of blockchain technology to provide tailored index insurance for investors in developing countries is impressive.', buttonText: 'Altruvestor Story' },
        { author: 'Author', company: 'Company', description: 'Our partnership has enabled us to offer more loans to clients and drive economic growth. ', buttonText: 'Investor Story' },
        { author: 'Author', company: 'Company', description: "Tranquil Capital's innovative, cost-effective, and tailored funds for investors are exactly what the world needs to accelerate economic growth.", buttonText: 'Investor Story' },
        { author: 'Author', company: 'Company', description: 'Tranquil’s disruptive innovations in the space industry have always kept us on our toes. They are a force to reckon with.', buttonText: 'Altruvestor Story' },
        { author: 'Author', company: 'Company', description: 'Collaborating with Tranquil has always been a pleasant experience. Their ability to simplify complex processes is truly remarkable.', buttonText: 'Altruvestor Story' },
        { author: 'Author', company: 'Company', description: 'At Amazon, we always look forward to Tranquil’s creative solutions to push the boundaries of e-commerce.', buttonText: 'Altruvestor Story' },
        { author: 'Author', company: 'Company', description: 'Tranquil has an uncanny ability to bring a fresh perspective to the table. Their innovative ideas have often helped us at Facebook.', buttonText: 'Altruvestor Story' },
    ];

    const [activeIndex, setActiveIndex] = useState(0);
    const testimonialsToShow = testimonials.slice(activeIndex, activeIndex + 3);

    return (
        <div className={styles['testimonial-full-container']}>
            <button
                className={`${styles.chevron} ${styles.left} ${activeIndex === 0 ? styles.disabled : ''}`}
                onClick={() => setActiveIndex(prevIndex => Math.max(prevIndex - 3, 0))}>
                <FontAwesomeIcon icon={faChevronLeft} />
            </button>
            <div className={styles['testimonial-container']}>
                {testimonialsToShow.map((testimonial, index) => (
                    <Testimonial key={index} {...testimonial} />
                ))}
            </div>
            <button
                className={`${styles.chevron} ${styles.right} ${activeIndex >= testimonials.length - 3 ? styles.disabled : ''}`}
                onClick={() => setActiveIndex(prevIndex => Math.min(prevIndex + 3, testimonials.length - 3))}>
                <FontAwesomeIcon icon={faChevronRight} />
            </button>
        </div>
    );
};

const OurClients = () => (
    <div className={styles['our-clients-main']}>
        <div className={styles['our-clients-top']}>
            <Title />
            <LogosGallery />
        </div>
        <Testimonials />
    </div>
);

export default OurClients;