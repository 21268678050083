import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import TextField from '../../../../shared/textfield/Textfield';
import sharedStyles from './path2.module.css';
import styles from './page1.module.css';
import { FormContext } from '../FormContext';

const Path2Page1 = () => {
    const navigate = useNavigate();
    const { formData, setFormData } = useContext(FormContext);
    const [firstName, setFirstName] = useState(formData.firstName);
    const [lastName, setLastName] = useState(formData.lastName);
    const [organisationName, setOrganisationName] = useState(formData.organisationName);

    const handleNext = () => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            firstName,
            lastName,
            organisationName,
        }));
        navigate('/getstarted/inv/investmentlocation');
    };

    const handleBackToMain = () => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            firstName,
            lastName,
            organisationName,
        }));
        navigate('/getstarted');
    };

    const isFormValid =
        firstName.trim() !== '' && lastName.trim() !== '' && organisationName.trim() !== '';

    return (
        <div className={sharedStyles['main-container']}>
            <div className={sharedStyles['page-header-text']}>
                <p className={sharedStyles['page-header-tiny-text']}>Quick Start</p>
                <h2 className={sharedStyles['page-header-descriptor-text']}>The Basics</h2>
            </div>
            <div className={styles['input-fields-upper']}>
                <div className={styles['text-fields-container']}>
                    <div className={styles['text-field-sizer']}>
                        <TextField
                            defaultLabel="Enter your first name"
                            focusLabel="FIRST NAME"
                            value={firstName}
                            onChange={setFirstName}
                            validation={(value) => (value.trim() === '' ? 'First name cannot be empty' : null)}
                            showErrors={true}
                            onInputChange={() => { }}
                        />
                    </div>
                    <div className={styles['text-field-sizer']}>
                        <TextField
                            defaultLabel="Enter your last name"
                            focusLabel="LAST NAME"
                            value={lastName}
                            onChange={setLastName}
                            validation={(value) => (value.trim() === '' ? 'Last name cannot be empty' : null)}
                            showErrors={true}
                            onInputChange={() => { }}
                        />
                    </div>
                </div>
                <div className={styles['text-field-sizer-large']}>
                    <TextField
                        defaultLabel="Enter your organisation name"
                        focusLabel="ORGANISATION"
                        value={organisationName}
                        onChange={setOrganisationName}
                        validation={(value) => (value.trim() === '' ? 'Organisation name cannot be empty' : null)}
                        showErrors={true}
                        onInputChange={() => { }}
                    />
                </div>
            </div>
            <div className={sharedStyles['button-group']}>
                <button className={`${sharedStyles['next-button']} ${isFormValid ? '' : sharedStyles['disabled']}`} onClick={handleNext} disabled={!isFormValid}>
                    <strong>Next</strong>
                </button>
                <button className={sharedStyles["back-button"]} onClick={handleBackToMain}>
                    <FontAwesomeIcon icon={faChevronLeft} className={sharedStyles["chevron-icon"]} />
                    <strong>Back</strong>
                </button>
            </div>
        </div>
    );
};

export default Path2Page1;
