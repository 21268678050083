import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { doc, updateDoc, increment, arrayUnion } from 'firebase/firestore'; // Import arrayUnion
import { db, auth } from '../configs.js';

// Initialize storage
const storage = getStorage();

export const uploadContract = (file, contractId, dispatch) => {
    return new Promise(async (resolve, reject) => {
        const currentUser = auth.currentUser;

        if (!currentUser) {
            console.error('No current user found.');
            return reject({ success: false, message: 'User not authenticated.' });
        }

        try {
            // Create a reference to the storage location
            const storageRef = ref(storage, `contracts/${contractId}`);

            // Upload file
            const uploadTask = uploadBytesResumable(storageRef, file);

            // Register observer for upload completion
            uploadTask.on('state_changed', () => { }, () => { }, async () => {
                // File uploaded successfully, now get the download URL
                const downloadURL = await getDownloadURL(storageRef);

                // Reference to the specific contract in Firestore
                const contractRef = doc(db, 'users', currentUser.uid, 'investments', contractId);

                // Define the contract history updates
                const contractHistoryUpdates = [
                    {
                        date: new Date().toISOString(), // Replace with your server timestamp method if needed
                        description: "Contract signed",
                    },
                    {
                        date: new Date().toISOString(), // Replace with your server timestamp method if needed
                        description: "Contract reviewed",
                    }
                ];

                // Update the contract with the file path, signed status, and history updates
                await updateDoc(contractRef, {
                    contract_file_path: downloadURL,
                    contract_signed: true,
                    status: "Active",
                    status_message: "Active",
                    contract_history: arrayUnion(...contractHistoryUpdates), // Use arrayUnion to add to the array
                });

                // Reference to the user's document
                const userRef = doc(db, 'users', currentUser.uid);

                // Increment the active_contracts field by 1
                await updateDoc(userRef, {
                    'contracts_overview.active_contracts': increment(1),
                });

                // dispatch(removeContract(contractId));

                console.log('Upload successful. Contract signed and path updated.');
                return resolve({ success: true, message: 'Upload successful.' });
            });

        } catch (error) {
            console.error('Error uploading contract:', error);
            return reject({ success: false, message: 'Error uploading contract.' });
        }
    });
};