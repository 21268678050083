import React from 'react';

// Components
import TopBar from '../../components/external/launchscreen/topbar/TopBar';
import Introduction from '../../components/external/launchscreen/introduction/Introduction';
import WhatWeDo from '../../components/external/launchscreen/whatwedo/WhatWeDo';
import HowItWorks from '../../components/external/launchscreen/howitworks/HowItWorks';
import UseCase from '../../components/external/launchscreen/usecase/UseCase';
import OurClients from '../../components/external/launchscreen/ourclients/OurClients';
import BottomBar from '../../components/external/launchscreen/bottombar/BottomBar';

const LaunchScreen = () => {
    return (
        <div className="app-container">
            <TopBar />
            <Introduction />
            <WhatWeDo />
            <HowItWorks />
            <UseCase />
            <OurClients />
            <BottomBar />
        </div>
    );
};

export default LaunchScreen;