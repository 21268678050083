import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import styles from './Introduction.module.css';

function IntroText() {
    return (
        <div className={styles['introduction-content']}>
            <h1 className={styles['intro-heading']}>Investment for impact.</h1>
            <p className={styles['intro-description']}>
                Secure, profitable, and transparent opportunities for sponsors and investors
                <br />
                looking to drive economic growth in developing countries.
            </p>
        </div>
    );
}

function StartNowButton() {
    const navigate = useNavigate();

    const handleClickSignup = () => {
        navigate('/getstarted');
    };

    return (
        <div className={styles['button-content']}>
            <button className={styles['start-now-button']} onClick={handleClickSignup}>
                Start Now <FontAwesomeIcon icon={faArrowRight} className={styles['start-now-chevron']} />
            </button>
        </div>
    );
}

function Introduction() {
    return (
        <section className={styles.introduction}>
            <div className={styles['blue-rectangle']}>
                <IntroText />
                <StartNowButton />
            </div>
        </section>
    );
}

export default Introduction;
