import React, { useState } from 'react';
import styles from './Textfield.module.css';

const TextField = ({
    defaultLabel,
    focusLabel,
    value,
    onChange,
    validation,
    showErrors,
    onInputChange,
    editable = true,
    numberFormat = false,
    currency = false,
    obscureText = false,
}) => {
    const [label, setLabel] = useState(value === "" ? defaultLabel : focusLabel);
    const [error, setError] = useState(null);

    const fieldId = `textfield-${defaultLabel.toLowerCase().replace(/\s+/g, '-')}`;

    const handleFocus = () => {
        setLabel(focusLabel);
        onInputChange();
    };

    const handleBlur = () => {
        if (!value) setLabel(defaultLabel);
        if (validation) {
            const errorMessage = validation(value);
            setError(errorMessage);
        }
    };

    const formatNumber = (value) => {
        return numberFormat ? value.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',') : value;
    };

    const formatCurrency = (value) => {
        const numValue = value.includes('$') ? value.replace(/\D/g, '') : value;
        return currency && numValue !== '' ? `$${formatNumber(numValue)}` : formatNumber(numValue);
    };

    const handleChange = (event) => {
        const newValue = event.target.value;
        if (editable && onChange) {
            onChange(newValue);
        }
        if (validation) {
            const errorMessage = validation(newValue);
            setError(errorMessage);
        }
        onInputChange();
    };

    return (
        <div
            className={`${styles.textfield} ${value ? styles.filled : ''} ${error && showErrors ? styles.error : ''
                }`}
        >
            <label htmlFor={fieldId} style={error && showErrors ? { color: 'red' } : {}}>
                {label}
            </label>
            <input
                type={obscureText ? 'password' : 'text'}
                id={fieldId}
                value={numberFormat ? formatCurrency(value) : currency ? formatCurrency(value) : value}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                readOnly={!editable}
                style={{ cursor: editable ? 'auto' : 'not-allowed' }}
            />
            {error && showErrors && <div className={styles['error-text']}>{error}</div>}
        </div>
    );
};

export default TextField;
