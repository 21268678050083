import React from 'react';
import styles from './Dashboard.module.css';
import Segment from '../../segment/Segment';
import Overview from '../../../../shared/internal/overview/Overview.js';

const SponsorDashboard = () => {
    // Overview design and value data
    const titles = [
        'Net Portfolio Worth',
        'Net Investment Worth',
        'Net Portfolio Gain',
        'Projects Supported',
    ];

    const srcs = [
        '/icons/internal/overviews/wallet.svg',
        '/icons/internal/overviews/coverage.svg',
        '/icons/internal/overviews/chart.svg',
        '/icons/internal/overviews/hash.svg',
    ];


    const values = [
        '$0K',
        '$0K',
        '$0K',
        '0',
    ];

    return (
        <div className={styles["body"]}>
            <div className={styles["page-header"]}>Dashboard</div>
            <div className={styles["page-segments"]}>
                <Segment title="Overview" segmentBody={<Overview srcs={srcs} titles={titles} values={values} />}></Segment>
            </div>
        </div>
    );
};

export default SponsorDashboard;
