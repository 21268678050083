import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import styles from './HowItWorks.module.css';

const TopSection = ({ focusedTextIndex, handleTextClick, selectedButton, setSelectedButton }) => {
    const resetText = () => {
        handleTextClick(1);
    };

    return (<div className={styles['section-top']}>
        <div className={styles['content-section']}>
            <TextContent />
            <div className={styles['buttons-container']}>
                <SponsorButton
                    selectedButton={selectedButton}
                    setSelectedButton={setSelectedButton}
                    resetText={resetText}
                />
                <InvestorButton
                    selectedButton={selectedButton}
                    setSelectedButton={setSelectedButton}
                    resetText={resetText}
                />
            </div>
        </div>
        <NavigationText handleTextClick={handleTextClick} focusedTextIndex={focusedTextIndex} selectedButton={selectedButton} />
    </div>);
}

const BottomSection = ({ isLeftDisabled, isRightDisabled, handleArrowClick, focusedTextIndex, handleImageLoad, imageLoaded, selectedButton }) => (
    <div className={styles['section-bottom']}>
        <NavigationArrows isLeftDisabled={isLeftDisabled} isRightDisabled={isRightDisabled} handleArrowClick={handleArrowClick} />
        <ImageView focusedTextIndex={focusedTextIndex} handleImageLoad={handleImageLoad} imageLoaded={imageLoaded} selectedButton={selectedButton} />
    </div>
);

const TextContent = () => (
    <div>
        <h1 className={styles['section-title']}>HOW IT WORKS</h1>
        <h2 className={styles['section-heading']}>Transforming risk <br /> into opportunity.</h2>
        <p className={styles['section-description']}>
            By quantifying the risks associated with <br /> natural disasters, market volatility, and <br /> geopolitical instability, Tranquil transforms <br /> risk into opportunity.
        </p>
    </div>
);

const SponsorButton = ({ selectedButton, setSelectedButton, resetText }) => (
    <button
        className={`${styles['sponsors-button']} ${selectedButton === "sponsors" ? styles['selected'] : ""}`}
        onClick={() => { setSelectedButton("sponsors"); resetText(); }}
    >
        Altruvestors
    </button>
);

const InvestorButton = ({ selectedButton, setSelectedButton, resetText }) => (
    <button
        className={`${styles['investors-button']} ${selectedButton === "investors" ? styles['selected'] : ""}`}
        onClick={() => { setSelectedButton("investors"); resetText(); }}
    >
        Investors
    </button>
);

const NavigationText = ({ handleTextClick, focusedTextIndex, selectedButton }) => {
    const isSpon = (selectedButton === "sponsors");

    return (<div className={styles['right-content-section']}>
        <div className={styles['text-container']}>
            <div
                className={`${styles['text-line']} ${focusedTextIndex === 1 ? styles['focused'] : ''}`}
                onClick={() => handleTextClick(1)}
            >
                <span className={styles['number']}>1</span>
                <span className={styles['text']}>{isSpon ? "Browse investment opportunities" : "Secure your investments against risk"}</span>
            </div>
            <div
                className={`${styles['text-line']} ${focusedTextIndex === 2 ? styles['focused'] : ''}`}
                onClick={() => handleTextClick(2)}
            >
                <span className={styles['number']}>2</span>
                <span className={styles['text']}>
                    {isSpon ? "Invest securely in projects that matter" : "Improve your social sharpe"}
                </span>
            </div>
            <div
                className={`${styles['text-line']} ${focusedTextIndex === 3 ? styles['focused'] : ''}`}
                onClick={() => handleTextClick(3)}
            >
                <span className={styles['number']}>3</span>
                <span className={styles['text']}>
                    {isSpon ? "Receive social and financial dividends" : "Receive automated payouts within days"}
                </span>
            </div>
        </div>
    </div>);
}

const NavigationArrows = ({ isLeftDisabled, isRightDisabled, handleArrowClick }) => (
    <div className={styles['navigation-container']}>
        <div className={styles['chevron-container']}>
            <button
                className={`${styles['chevron-button']} ${isLeftDisabled ? styles['disabled'] : ''}`}
                onClick={() => handleArrowClick('left')}
                disabled={isLeftDisabled}
            >
                <FontAwesomeIcon icon={faChevronLeft} />
            </button>
            <button
                className={`${styles['chevron-button']} ${isRightDisabled ? styles['disabled'] : ''}`}
                onClick={() => handleArrowClick('right')}
                disabled={isRightDisabled}
            >
                <FontAwesomeIcon icon={faChevronRight} />
            </button>
        </div>
    </div>
);

const sponsorImages = [
    '/images/launch/howitworks/sponsor_1.png',
    '/images/launch/howitworks/sponsor_2.png',
    '/images/launch/howitworks/sponsor_3.png',
];
const investorImages = [
    '/images/launch/howitworks/investor_1.png',
    '/images/launch/howitworks/investor_2.png',
    '/images/launch/howitworks/investor_3.png',
];

// Preload the images and assign them to variables
const preloadedSponsorImages = sponsorImages.map((path) => {
    const img = new Image();
    img.src = path;
    return img;
});
const preloadedInvestorImages = investorImages.map((path) => {
    const img = new Image();
    img.src = path;
    return img;
});

const ImageView = ({ focusedTextIndex, handleImageLoad, imageLoaded, selectedButton }) => {
    const getImageSource = () => {
        if (selectedButton === "sponsors") {
            return preloadedSponsorImages[focusedTextIndex - 1].src;
        } else {
            return preloadedInvestorImages[focusedTextIndex - 1].src;
        }
    };

    return (
        <div className={styles['how-it-works-image-container']}>
            <div className={`${styles['how-it-works-image']} ${imageLoaded ? styles['image-loaded'] : ''}`}>
                <div className={styles['image-overlay']} />
                <img
                    src={getImageSource()}
                    alt="Projects"
                    onLoad={handleImageLoad}
                    style={{ display: imageLoaded ? 'block' : 'none' }}
                />
            </div>
        </div>
    );
};

const HowItWorks = () => {
    const [focusedTextIndex, setFocusedTextIndex] = useState(1);
    const [imageLoaded, setImageLoaded] = useState(false);
    const [selectedButton, setSelectedButton] = useState("sponsors"); // Here we initialize the selectedButton state to 'sponsors'

    const handleTextClick = (index) => {
        setFocusedTextIndex(index);
    };

    const handleArrowClick = (direction) => {
        if (direction === 'left') {
            setFocusedTextIndex((prevIndex) => Math.max(prevIndex - 1, 1));
        } else if (direction === 'right') {
            setFocusedTextIndex((prevIndex) => Math.min(prevIndex + 1, 3));
        }
    };

    const handleImageLoad = () => {
        setImageLoaded(true);
    };

    const isLeftDisabled = focusedTextIndex === 1;
    const isRightDisabled = focusedTextIndex === 3;

    return (
        <div className={styles['how-it-works']}>
            <TopSection
                handleTextClick={handleTextClick}
                focusedTextIndex={focusedTextIndex}
                selectedButton={selectedButton}
                setSelectedButton={setSelectedButton}
            />
            <BottomSection isLeftDisabled={isLeftDisabled} isRightDisabled={isRightDisabled} handleArrowClick={handleArrowClick} focusedTextIndex={focusedTextIndex} handleImageLoad={handleImageLoad} imageLoaded={imageLoaded} selectedButton={selectedButton} />
        </div>
    );
};

export default HowItWorks;
