import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin, faTwitter, faTiktok, faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons';
import styles from './BottomBar.module.css';

function BottomBar() {
    return (
        <div className={styles['bottom-bar']}>
            <div className={styles['links-container']}>
                <div className={styles['links-column']}>
                    <h1>ABOUT US</h1>
                    <a href="/ourstory">Our Story</a>
                    <a href="/missionstatement">Mission Statement</a>
                    <a href="termsofservice">Terms of Service</a>
                    <a href="privacypolicy">Privacy Policy</a>
                </div>
                <div className={styles['links-column']}>
                    <h1>PRODUCTS</h1>
                    <a href="tranquilaltruvesting">Tranquil Altruvesting</a>
                    <a href="tranquilinvestment">Tranquil Investment</a>
                </div>
                <div className={styles['links-column']}>
                    <h1>COMPANY</h1>
                    <a href="ourteam">Our Team</a>
                    <a href="careercentre">Career Centre</a>
                </div>
                <div className={styles['links-column']}>
                    <h1>INSIGHTS</h1>
                    <a href="blog">Blog</a>
                    <a href="casestudies">Case Studies</a>
                    <a href="media">Media</a>
                </div>
                <div className={styles['links-column']}>
                    <h1>RESOURCES</h1>
                    <a href="contact">Contact</a>
                    <a href="faq">FAQ</a>
                    <a href="signin">Login or Signup</a>
                </div>
            </div>
            <div className={styles['line']}></div>
            <div className={styles['brand-container']}>
                <h1 className={styles['brand-name']}>TRANQUIL CAPITAL</h1>
                <div className={styles['social-media-icons']}>
                    <FontAwesomeIcon className={styles['social-media-icon']} icon={faLinkedin} color="white" />
                    <FontAwesomeIcon className={styles['social-media-icon']} icon={faGithub} color="white" />
                    <FontAwesomeIcon className={styles['social-media-icon']} icon={faFacebook} color="white" />
                    <FontAwesomeIcon className={styles['social-media-icon']} icon={faTwitter} color="white" />
                    <FontAwesomeIcon className={styles['social-media-icon']} icon={faTiktok} color="white" />
                    <FontAwesomeIcon className={styles['social-media-icon']} icon={faInstagram} color="white" />
                </div>
            </div>
        </div>
    );
}

export default BottomBar;
