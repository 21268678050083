import React, { useState, useEffect } from 'react';
import styles from './ContractDetails.module.css';
import Segment from '../../../segment/Segment.js';
import Overview from '../../../../../shared/internal/overview/Overview.js';
import useSubscribeToAllContracts from '../../../../../../firebase/services/useSubscribeToAllContracts.js';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import SignContractPanel from './SignContractSeg';
import { db } from '../../../../../../firebase/configs.js';
import { collection, query, where, getDocs } from 'firebase/firestore';
import ContractPDF from './ContractPDF.js';
import { pdf } from '@react-pdf/renderer';
import { uploadContract } from '../../../../../../firebase/services/pendingContractUpload.js';
import { useDispatch } from 'react-redux';
import Index from './modules/Index';
import Alerts from './modules/Alerts';
import Table from '../../../../../shared/internal/table/Table';

const ContractDetails = () => {
    const { contractId } = useParams();
    const [contract, setContract] = useState(null);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);

    // Dummy data for index
    const data = [
        20, 25, 29, 44, 30, 78, 55, 42, 48, 22,
        66, 86, 80, 84, 65, 81, 69, 62, 58, 68,
        82, 77, 69, 65, 40, 68, 85, 55, 77, 71
    ];

    const average = data.reduce((sum, value) => sum + value, 0) / data.length;
    const threshold = 85;

    // Dummy data for alerts
    const alertsData = {
        'Today': [
            ['Weather Warning', 2, 'The temperature is expected to exceed 30°C for the next 6 days. Please take necessary actions.'],
        ],
        'This Month': [
            ['Threshold Peak Notice', 3, 'The recorded weather data came within 10% of the threshold.'],
            ['Threshold Peak Notice', 3, 'The recorded weather data came within 20% of the threshold.'],
            ['Weather Warning', 2, 'Temperature is expected to climb for the next 4 days. And humidity is set to decrease. Please take the necessary actions.'],
        ],
        '2 Months Ago': [
            ['Foreign Exchange Alert', 1, 'The AUD/USD exchange rate fluctuated by more than 5% in the past month. Monitor your investments.'],
            ['Interest Rate Warning', 2, 'The federal interest rate was increased by 0.5%. Consider refinancing options.'],
            ['Weather Warning', 3, 'Heavy rainfall expected in the coastal regions. Possible flooding in low-lying areas.'],
        ],
        '4 Months Ago': [
            ['Political Risk Alert', 0, 'Upcoming elections may cause market volatility. Stay informed.'],
            ['Threshold Peak Notice', 3, 'The recorded temperature data came within 15% of the threshold.'],
            ['Weather Warning', 2, 'Prolonged drought conditions expected in the northern regions. Water restrictions may apply.'],
            ['Market Fluctuation Notice', 1, 'Significant market fluctuations detected. Review investment strategies.'],
        ]
    };

    // Data for history
    const contractHistoryData = contract && contract.contract_history
        ? contract.contract_history.map((history) => [
            new Date(history.date).toLocaleDateString('en-GB'),
            history.description,
        ])
        : [];

    const headers = ['Date', 'Description'];
    const types = ['text', 'text'];
    const columnWidths = [1, 1];

    // Using your custom hook at the top level
    useSubscribeToAllContracts();

    // Getting all the pending contracts from the Redux store
    const contracts = useSelector((state) => state.contracts);

    // Finding the specific contract using the contractId
    useEffect(() => {
        console.log("EFFECT");
        console.log([...contracts.pending, ...contracts.active]);
        const foundContract = [...contracts.pending, ...contracts.active].find((contract) => contract.id === contractId);
        setContract(foundContract);
    }, [contracts, contractId]);

    if (!contract) {
        return <div>Contract not found</div>; // You may replace this with a custom error component
    }

    // Overview design and value data
    const srcs = [
        '/icons/internal/overviews/contracts.svg',
        '/icons/internal/overviews/coverage.svg',
        '/icons/internal/overviews/calendar.svg',
        '/icons/internal/overviews/hash.svg',
    ];

    const risksCovered = Object.keys(contract.selected_risk_thresholds).length;
    const residualTerm = contract.residual_term;

    const titles = [
        'Investment Value',
        'Total Coverage Value',
        (residualTerm === 1) ? 'Term Day Remaining' : 'Term Days Remaining',
        (risksCovered === 1) ? 'Risk Covered' : 'Risks Covered',
    ];

    const values = [
        `$${contract.net_value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`, // Investment Value
        `${contract.coverage}%`, // Total Coverage Value
        `${residualTerm}`, // Term Days Remaining
        `${risksCovered}`, // Risks Covered
    ];

    async function fetchDocument(
        mean_temp_deviation,
        precip_deviation,
        political_risk,
        AUDUSD_return,
        fed_rate,
        cds_spread
    ) {
        // Define the query
        const pricingQuery = query(
            collection(db, 'pricing'),
            where('mean_temp_deviation', '==', mean_temp_deviation),
            where('precip_deviation', '==', precip_deviation),
            where('political_risk', '==', political_risk),
            where('AUDUSD_return', '==', AUDUSD_return),
            where('fed_rate', '==', fed_rate),
            where('cds_spread', '==', cds_spread),
            where('price', '!=', 0)
        );

        // Execute the query
        const querySnapshot = await getDocs(pricingQuery);

        // Handle results
        if (querySnapshot.empty) {
            console.error('No matching documents found');
            return null;
        }

        if (querySnapshot.size > 1) {
            console.error('Multiple matching documents found, only expecting one');
            return null;
        }

        const doc = querySnapshot.docs[0];
        console.log('Document data:', doc.data());
        return doc.data();
    }

    function gcd(a, b) {
        return b === 0 ? a : gcd(b, a % b);
    }

    function scaleValues(dataMap) {
        // Create a copy of the dataMap to avoid modifying the original object
        const copiedDataMap = { ...dataMap };

        // Convert to integer values
        for (let key in copiedDataMap) {
            copiedDataMap[key] = Math.round(copiedDataMap[key] / 33.3333);
        }

        let values = Object.values(copiedDataMap);

        let commonDivisor = values.reduce(gcd);

        console.log(commonDivisor);

        // If all values are 0, set commonDivisor to 1 to avoid division by zero
        if (commonDivisor === 0) commonDivisor = 1;

        for (let key in copiedDataMap) {
            // Divide by the common divisor, then scale the value to the range 0-4
            copiedDataMap[key] = copiedDataMap[key] / commonDivisor;
        }

        return copiedDataMap;
    }


    function getValue(map, key, defaultValue = 0) {
        return map[key] !== undefined ? map[key] : defaultValue;
    }

    const handleDownload = async () => {
        setIsLoading(true);
        try {
            // Convert selected risk thresholds to reduced ratio
            const reducedRatio = 1; scaleValues(contract.selected_risk_thresholds);
            const investmentAmount = Math.round(contract.coverage / 100 * contract.net_value);

            // Selected data
            const mean_temp_deviation = 0;
            const precip_deviation = getValue(reducedRatio, 'Weather');
            const political_risk = 0;
            const AUDUSD_return = getValue(reducedRatio, 'Foreign Exchange');
            const fed_rate = getValue(reducedRatio, 'Interest');
            const cds_spread = getValue(reducedRatio, 'Political');

            console.log(mean_temp_deviation, precip_deviation, political_risk, AUDUSD_return, fed_rate, cds_spread);

            // Fetch the data
            const documentData = await fetchDocument(mean_temp_deviation, precip_deviation, political_risk, AUDUSD_return, fed_rate, cds_spread);

            // Calculate protection costings from fetched data
            const protectionCost = documentData == null ? investmentAmount * 0.0001 : investmentAmount * documentData.price;

            const blob = await pdf(<ContractPDF contract={contract} protectionCost={protectionCost} />).toBlob();

            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'contract.pdf';
            link.click();
        } finally {
            setIsLoading(false);
        }
    };

    const handleUpload = () => {
        // Create a hidden file input element
        const input = document.createElement('input');
        input.type = 'file';

        // Attach an event listener to the input element
        input.addEventListener('change', async (event) => {
            if (input.files.length === 0) {
                // User canceled the dialog or didn't select a file
                setIsLoading(false);
                return;
            }

            const file = event.target.files[0];

            try {
                setIsLoading(true);
                // Call the uploadContract function with the selected file and contract ID
                await uploadContract(file, contractId, dispatch)
                    .then((result) => {
                        // Handle successful upload
                        console.log(result.message);
                    })
                    .catch((error) => {
                        // Handle failed upload
                        console.error(error.message);
                    });
            } catch (error) {
                console.error('Error uploading contract:', error);
            } finally {
                setIsLoading(false);
            }
        });

        // Trigger the file picker dialog
        input.click();
    };

    return (
        <div className={styles["body"]}>
            <div className={styles["page-header"]}>{`${contract.project_name} (${contract.code})`}</div>
            <div className={styles["page-segments"]}>
                <Segment title="Overview" segmentBody={<Overview srcs={srcs} titles={titles} values={values} />}></Segment>
                {!contract.contract_signed && (
                    <Segment title="Sign Contract" segmentBody=
                        {<div>
                            <SignContractPanel
                                onDownload={handleDownload}
                                onUpload={handleUpload}
                                isLoading={isLoading}
                            />
                        </div>}>
                    </Segment>
                )}
                {contract.status === "Active" && (
                    <div>
                        <div className={styles["row"]}>
                            <div className={styles["indexSegment"]}>
                                <Segment title="Index" segmentBody={
                                    <div>
                                        <Index risks={contract.available_risks} data={data} average={average} threshold={threshold} />
                                    </div>}>
                                </Segment>
                            </div>
                            <div className={styles["alertsSegment"]}>
                                <Segment title="Alerts" segmentBody={
                                    <div>
                                        <Alerts alerts={alertsData}></Alerts>
                                    </div>}>
                                </Segment>
                            </div>
                        </div>
                        <Segment title="Contract History" segmentBody={
                            <div>
                                <Table
                                    headers={headers}
                                    types={types}
                                    data={contractHistoryData}
                                    columnWidths={columnWidths}
                                    emptyMessage="No contract history available."
                                />
                            </div>
                        }>
                        </Segment>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ContractDetails;
