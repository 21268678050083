import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import TextField from '../../../../shared/textfield/Textfield.js';
import Dropdown from '../../../../shared/dropdown/Dropdown.js';
import styles from './page1.module.css';
import sharedStyles from './path1.module.css';
import { FormContext } from '../FormContext.js';
import RadioButton from '../../../../shared/radiobuttons/RadioButtons.js';

const Path1Page1 = () => {
    const navigate = useNavigate();
    const { formData, setFormData } = useContext(FormContext);
    const [firstName, setFirstName] = useState(formData.firstName);
    const [lastName, setLastName] = useState(formData.lastName);
    const [selectedRadio, setSelectedRadio] = useState(formData.sponsorType);
    const [selectedInvestorType, setSelectedInvestorType] = useState(formData.investorType);

    const handleNext = () => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            firstName,
            lastName,
            sponsorType: selectedRadio,
            investorType: selectedInvestorType,
        }));
        navigate('/getstarted/alt/investmentinterests');
    };

    const handleBackToMain = () => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            firstName,
            lastName,
            sponsorType: selectedRadio,
            investorType: selectedInvestorType,
        }));
        navigate('/getstarted');
    };

    const isFormValid = firstName.trim() !== '' && lastName.trim() !== '';

    // Define the options for the dropdown
    const dropdownOptions = ['Individual Investor', 'Angel Investor', 'Venture Capitalist', 'Impact Investor'];
    const radioOptions = ['Individual', 'Organisation'];

    return (
        <div className={sharedStyles['main-container']}>
            <div className={sharedStyles['page-header-text']}>
                <p className={sharedStyles['page-header-tiny-text']}>Quick Start</p>
                <h2 className={sharedStyles['page-header-descriptor-text']}>The Basics</h2>
            </div>
            <div className={styles['input-fields']}>
                <div className={styles['radio-buttons']}>
                    <RadioButton list={radioOptions} direction="horizontal" selectedOption={selectedRadio} onChange={setSelectedRadio} />
                </div>
                <div className={styles['text-fields-container']}>
                    <div className={styles['text-field-sizer']}>
                        <TextField
                            defaultLabel="Enter your first name"
                            focusLabel="FIRST NAME"
                            value={firstName}
                            onChange={setFirstName}
                            validation={(value) => (value.trim() === '' ? 'First name cannot be empty' : null)}
                            showErrors={true}
                            onInputChange={() => { }}
                        />
                    </div>
                    <div className={styles['text-field-sizer']}>
                        <TextField
                            defaultLabel="Enter your last name"
                            focusLabel="LAST NAME"
                            value={lastName}
                            onChange={setLastName}
                            validation={(value) => (value.trim() === '' ? 'Last name cannot be empty' : null)}
                            showErrors={true}
                            onInputChange={() => { }}
                        />
                    </div>
                </div>
                <div className={styles['drop-down-sizer']}>
                    <Dropdown options={dropdownOptions} defaultLabel="INVESTOR TYPE" selectedOption={selectedInvestorType} onChange={setSelectedInvestorType} />
                </div>
            </div>
            <div className={sharedStyles['button-group']}>
                <button className={`${sharedStyles['next-button']} ${isFormValid ? '' : sharedStyles['disabled']}`} onClick={handleNext} disabled={!isFormValid}>
                    <strong>Next</strong>
                </button>
                <button className={sharedStyles['back-button']} onClick={handleBackToMain}>
                    <FontAwesomeIcon icon={faChevronLeft} className={sharedStyles['chevron-icon']} />
                    <strong>Back</strong>
                </button>
            </div>
        </div>
    );
};

export default Path1Page1;
