import React, { useState } from 'react';
import styles from './Index.module.css';
import Checkboxes from '../../../../../../shared/checkbox/Checkboxes.js';

const Index = ({ data, average, threshold, risks }) => {
    const [showThreshold, setShowThreshold] = useState(true);
    const [showAverage, setShowAverage] = useState(true);

    return (
        <div className={styles.wrapper}>
            <div className={styles.keyContainer}>
                <div className={styles.risks}>
                    {risks.length === 1 ? risks[0] : "Risk Bundle: " + risks.join(', ')}
                </div>
                <div className={styles.checkboxesContainer}>
                    <Checkboxes
                        list={[{ text: 'Threshold' }]}
                        onChange={(val) => setShowThreshold(val)}
                        color="black"
                        single={true}
                        startOn={true}
                    />
                    <Checkboxes
                        list={[{ text: 'Average' }]}
                        onChange={(val) => setShowAverage(val)}
                        color="var(--theme-blue)"
                        single={true}
                        startOn={true}
                    />
                </div>
            </div>
            <div className={styles.container}>
                <div className={styles.grid}>
                    {data.map((value, index) => (
                        <div key={index} className={styles.barContainer}>
                            <div className={styles.bar} style={{ height: `${value}%` }}></div>
                            <div className={styles.axisLabel}>{index + 1}</div>
                        </div>
                    ))}
                </div>
                {showAverage && <div className={styles.averageLine} style={{ bottom: `calc(${average}% + 14px)` }}></div>}
                {showThreshold && <div className={styles.thresholdLine} style={{ bottom: `calc(${threshold}% + 14px)` }}></div>}
            </div>
        </div>
    );
};

export default Index;
