import React from 'react';
import styles from './Segment.module.css';

const Segment = ({ title, segmentBody }) => {
    return (
        <div className={styles["segment"]}>
            <div className={styles["segment-title"]}>
                {title}
            </div>
            <div className={styles["segment-body"]}>
                {segmentBody}
            </div>
        </div>
    );
};

export default Segment;