import React, { createContext, useState } from 'react';

const initialFormData = {
    sponsorType: 'Individual',
    firstName: '',
    lastName: '',
    investorType: 'Individual Investor',
    interests: [
        { text: 'Microfinance', selected: false },
        { text: 'Agriculture', selected: false },
        { text: 'Education', selected: false },
        { text: 'Renewable Energy', selected: false },
        { text: 'Healthcare', selected: false },
        { text: 'Water & Sanitation', selected: false },
        { text: 'Infrastructure', selected: false },
        { text: 'Youth Development', selected: false },
        { text: 'Tourism', selected: false },
        { text: 'Food Security', selected: false },
        { text: 'Climate', selected: false },
        { text: 'Disaster Resilience', selected: false },
        { text: 'Urban Planning', selected: false },
        { text: 'Heritage Preservation', selected: false },
    ],
    email: '',
    password: '',
    organisationName: '',
    investmentAddress: '',
    risks: {
        Weather: false,
        Political: false,
        Interest: false,
        'Foreign Exchange': false,
        Cyber: null,
    },
    risksThreshold: {
        Weather: 0.0,
        Political: 0.0,
        Interest: 0.0,
        'Foreign Exchange': 0.0,
        Cyber: null,
    },
    coverage: 0.0,
    netValue: '',
    currency: 'USD',
};


export const FormContext = createContext();

export const FormProvider = ({ children }) => {
    const [formData, setFormData] = useState(initialFormData);

    return (
        <FormContext.Provider value={{ formData, setFormData }}>
            {children}
        </FormContext.Provider>
    );
};