import React, { useContext, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import styles from './page5.module.css'; // Add the CSS file for page5
import sharedStyles from './path2.module.css';
import Slider from '../../../../shared/slider/Slider.js';
import { FormContext } from '../FormContext';

const iconsMap = {
    Weather: 'weather.svg',
    Political: 'political.svg',
    Interest: 'interest.svg',
    Cyber: 'cyber.svg',
    'Foreign Exchange': 'exchange.svg',
};

const Square = ({ title, active }) => {
    const iconFileName = iconsMap[title];

    return (
        <div className={`${styles.square} ${active ? styles.highlighted : ''}`}>
            <div className={styles['square-content']}>
                <div className={`${styles.icon} ${active ? styles.highlighted : ''}`}>
                    <img src={`/icons/riskcoverage/${iconFileName}`} alt={title} />
                </div>
                <div className={`${styles.title} ${active ? styles.highlighted : ''}`}>
                    {title}
                </div>
            </div>
        </div>
    );
};

const Path2Page5 = () => {
    const navigate = useNavigate();
    const { formData, setFormData } = useContext(FormContext);
    const [selectedRisks, setSelectedRisks] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isNextEnabled, setIsNextEnabled] = useState(false);
    const [sliderValue, setSliderValue] = useState(50);

    const prevSelectedRisks = useRef(selectedRisks);

    useEffect(() => {
        const getSelectedRisks = () => {
            const selectedRisksList = Object.entries(formData.risks).filter(
                ([, isSelected]) => isSelected
            );
            const selectedRiskTitles = selectedRisksList.map(([risk]) => risk);
            return selectedRiskTitles;
        };

        setSelectedRisks(getSelectedRisks());
    }, [formData.risks]);

    useEffect(() => {
        const currentRisk = selectedRisks[currentIndex];
        const initialValue = formData.risksThreshold[currentRisk];

        setSliderValue(initialValue);
    }, [currentIndex, formData.risksThreshold, selectedRisks]);

    useEffect(() => {
        if (JSON.stringify(prevSelectedRisks.current) !== JSON.stringify(selectedRisks)) {
            prevSelectedRisks.current = selectedRisks;
            const currentRisk = selectedRisks[currentIndex];
            const initialValue = formData.risksThreshold[currentRisk];
            setSliderValue(initialValue);
        }

        const areAllThresholdsSet = selectedRisks.every(
            (risk) => formData.risksThreshold[risk] !== null
        );
        setIsNextEnabled(areAllThresholdsSet);
    }, [currentIndex, formData.risksThreshold, selectedRisks]);

    const handleNext = () => {
        if (currentIndex < selectedRisks.length - 1) {
            setCurrentIndex((prevIndex) => prevIndex + 1);
        } else {
            navigate('/getstarted/inv/accountdetails');
        }
    };

    const handleBack = () => {
        if (currentIndex > 0) {
            setCurrentIndex((prevIndex) => prevIndex - 1);
        } else {
            navigate('/getstarted/inv/coverageamount');
        }
    };

    const handleSliderChange = (newValue) => {
        const currentRisk = selectedRisks[currentIndex];

        if (currentRisk !== null && currentRisk !== undefined) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                risksThreshold: {
                    ...prevFormData.risksThreshold,
                    [currentRisk]: newValue,
                },
            }));
        }
    };

    return (
        <div className={sharedStyles['main-container']}>
            <div className={sharedStyles['page-header-text']}>
                <p className={sharedStyles['page-header-tiny-text']}>Risk Threshold Scaling</p>
                <h2 className={sharedStyles['page-header-descriptor-text']}>
                    Select Bundle Ratio Values for Each Selected Risk
                </h2>
            </div>
            <div className={styles['body']}>
                <div className={styles['squares-container-wrapper']}>
                    <div className={styles['squares-container']}>
                        {selectedRisks.map((selectedRisk) => (
                            <Square key={selectedRisk} title={selectedRisk} active={selectedRisks[currentIndex] === selectedRisk} />
                        ))}
                    </div>
                    <div className={styles['slider-container']}>
                        <Slider
                            label="BUNDLE RISK RATIO"
                            maxValue={100}
                            divisions={3}
                            initial={sliderValue}
                            storeDivision={true}
                            onChange={handleSliderChange}
                            showDivisionNumber={true}
                            renderFloater={false}
                        />
                    </div>
                </div>
            </div>
            <div className={sharedStyles['button-group']}>
                <button className={`${sharedStyles['next-button']} ${isNextEnabled ? '' : sharedStyles['disabled']}`} onClick={handleNext} disabled={!isNextEnabled}>
                    <strong>Next</strong>
                </button>
                <button className={sharedStyles['back-button']} onClick={handleBack}>
                    <FontAwesomeIcon icon={faChevronLeft} className={sharedStyles['chevron-icon']} />
                    <strong>Back</strong>
                </button>
            </div>
        </div>
    );
};

export default Path2Page5;
