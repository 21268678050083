import React from 'react';
import styles from './TopBar.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { signout } from '../../../firebase/services/accountServices.js';
import { useDispatch } from 'react-redux';

// Database service management
import { useSelector } from 'react-redux';

function TopBar({ onSidebarToggle, collapsed }) {
    const navigate = useNavigate();
    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();


    const getInitials = () => {
        if (user && typeof user.first_name === 'string' && typeof user.last_name === 'string' && user.first_name.length > 0 && user.last_name.length > 0) {
            return user.first_name[0] + user.last_name[0];
        }
        return 'LL'; // Default initials if not available
    };

    const getFullname = () => {
        if (user && typeof user.first_name === 'string' && typeof user.last_name === 'string') {
            return `${user.first_name} ${user.last_name}`;
        }
        return 'Guest'; // Default fullname if not available
    };

    const handleSignOut = async () => {
        const signOutResult = await signout(dispatch);
        if (signOutResult.success) {
            console.log("Successfully signed out.");
            navigate('/');
        } else {
            console.log("Error during signout:", signOutResult.message);
        }
    };

    return (
        <div className={styles['top-bar']}>
            <div className={`${styles['logo']} ${collapsed ? styles['collapsed'] : ''}`}>
                <BrandText onSidebarToggle={onSidebarToggle} collapsed={collapsed} />
            </div>
            <div className={`${styles['main-panel']} ${collapsed ? styles['collapsed'] : ''}`}>
                <div className={styles['search']}>
                    <div className={styles['search-bar']}>
                        <img src="/icons/internal/topbar/search.svg" alt="Search Icon" className={styles['search-icon']} />
                        <input type="text" className={styles['search-input']} placeholder="Search..." />
                    </div>
                </div>
                <div className={styles['actions']}>
                    <div className={styles['avatar-circle']}>
                        <img src="/icons/internal/topbar/mail.svg" alt="Mail Icon" className={styles['avatar-icon']} />
                    </div>
                    <div className={styles['avatar-circle']}>
                        <img src="/icons/internal/topbar/bell.svg" alt="Bell Icon" className={styles['avatar-icon']} />
                    </div>
                    <div className={styles['avatar-profile']} onClick={handleSignOut}>
                        {getInitials()}
                        {/* <img src="/icons/internal/topbar/dalton.png" alt="Dalton" className={styles['profile-pic']} /> */}
                    </div>
                    <div className={styles['profile-name']} onClick={handleSignOut}>
                        {getFullname()}
                        <FontAwesomeIcon icon={faChevronDown} className={styles['chevron-down']} />
                    </div>
                </div>
            </div>
        </div>
    );
}

function BrandText({ onSidebarToggle, collapsed }) {
    const handleClickBrand = () => {
        onSidebarToggle();
    };

    return (
        <div className={styles['brand']} onClick={handleClickBrand}>
            {collapsed ? <img src={"/images/logo_collapsed.png"} alt="TC" /> : <img src={"/images/logo_horizontal.png"} alt="Tranquil Capital" />}
        </div>
    );
}

export default TopBar;
