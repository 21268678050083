import React from 'react';
import styles from './Impact.module.css';
import Segment from '../../segment/Segment';
import Overview from '../../../../shared/internal/overview/Overview';
import Spread from './spread/Spread.js';

const SponsorImpact = () => {
    // Spread data
    const names = [
        "Microfinance",
        "Youth Development",
        "Agriculture",
        "Tourism",
        "Education",
        "Renewable Energy",
        "Healthcare",
        "Food Security",
        "Climate",
        "Disaster Resilience",
        "Water & Sanitation",
        "Infrastructure",
        "Urban Planning",
        "Heritage Preservation",
    ];
    const opacities = [
        0.5,
        0.3,
        0,
        0,
        0.025,
        0,
        0.75,
        0,
        0,
        0.2,
        0,
        0,
        0.1,
        0.025,
    ];

    console.log(opacities);

    // Overview design and value data
    const titles = [
        'Total Investment Contribution',
        'Unlocked Investments',
        'Expansion Factor',
        'Projects Supported',
    ];

    const srcs = [
        '/icons/internal/overviews/piggy.svg',
        '/icons/internal/overviews/unlock.svg',
        '/icons/internal/overviews/cross.svg',
        '/icons/internal/overviews/impact.svg',
    ];


    const values = [
        '$50K',
        '$500K',
        '10',
        '5',
    ];

    return (
        <div className={styles["body"]}>
            <div className={styles["page-header"]}>Impact</div>
            <div className={styles["page-segments"]}>
                <Segment title="Spread" segmentBody={<Spread names={names} opacities={opacities}></Spread>}></Segment>
                <Segment title="Overview & Projections" segmentBody={<Overview srcs={srcs} titles={titles} values={values} />}></Segment>
            </div>
        </div>
    );
};

export default SponsorImpact;
