import React, { useState, useEffect } from 'react';
import styles from './Slider.module.css';

const Slider = ({ label, maxValue, divisions, initial, storeDivision = false, onChange, showDivisionNumber = false, renderFloater = true }) => {
    const initialSliderValue = initial !== undefined ? initial : Math.floor(maxValue * 0.5);
    const [sliderValue, setSliderValue] = useState(initialSliderValue);
    const [showFloatingLabel, setShowFloatingLabel] = useState(false);

    function extractAndConvertToInteger(inputString) {
        const numberString = inputString.toString().replace(/\D/g, '');
        const integerValue = parseInt(numberString, 10);
        return integerValue;
    }

    const formatCurrency = (value) => {
        if (showDivisionNumber) {
            const divisionValue = maxValue / divisions;
            const divisionNumber = Math.floor(value / divisionValue);
            return `${divisionNumber} / ${divisions}`;
        } else {
            const maxValueInteger = extractAndConvertToInteger(maxValue);
            const formattedValueCents = Math.round((value / 100) * maxValueInteger);
            const formattedValueString = formattedValueCents.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            let resultString = `$${formattedValueString}`;

            if (resultString === '$NaN') resultString = '$?';

            return resultString;
        }
    };

    const handleSliderChange = (event) => {
        const newValue = event.target.value;
        setSliderValue(newValue);
    };

    const handleSliderRelease = () => {
        if (divisions <= 0) return;
        const divisionValue = 100 / divisions;
        const newValue = Math.round(sliderValue / divisionValue) * divisionValue;
        setSliderValue(newValue);
        setShowFloatingLabel(false);
        onChange(newValue);
    };

    const renderDivisions = () => {
        if (divisions <= 0) return null;
        const divisionPoints = Array.from({ length: divisions + 1 }, (_, index) => index / divisions);
        return divisionPoints.map((point) => (
            <div key={point} className={styles.division} style={{ left: `${point * 100}%` }} />
        ));
    };

    const renderFloatingLabel = () => {
        const percentage = Math.round(sliderValue);
        const floatingLabelStyle = {
            left: `${percentage * 0.91}%`,
        };

        return (
            <div
                className={styles.floatingLabel}
                style={{ ...floatingLabelStyle, display: showFloatingLabel ? 'block' : 'none' }}
            >
                {percentage}%
            </div>
        );
    };

    useEffect(() => {
        if (initial !== undefined) {
            setSliderValue(initial);
        }
    }, [initial]);

    return (
        <div className={styles.sliderContainer}>
            <div className={styles.header}>
                <span className={styles.label}>{label}</span>
                <span className={styles.value}>{formatCurrency(sliderValue)}</span>
            </div>
            {renderFloatingLabel()}
            {/* {renderFloater ? renderFloatingLabel() : null} */}
            <div className={styles.trackAndRange}>
                <div className={styles.track}>
                    <div
                        className={styles.filledTrack}
                        style={{ width: `${sliderValue}%` }}
                    ></div>
                    {renderDivisions()}
                </div>
                <input
                    type="range"
                    min="0"
                    max={maxValue}
                    step={1 / (divisions || 1)}
                    value={sliderValue}
                    onChange={handleSliderChange}
                    onMouseUp={handleSliderRelease}
                    onMouseDown={() => setShowFloatingLabel(true)}
                    className={styles.rangeInput}
                />
            </div>
        </div>
    );
};

export default Slider;