import React from 'react';
import styles from './PrivacyPolicy.module.css';
import TopBar from './TopBar';

const PrivacyPolicy = () => (
    <div className={styles['app-container']}>
        <TopBar></TopBar>
        <div className={styles['privacy-policy-container']}>
            <h1>Privacy Policy</h1>
            <p>Your privacy is important to us. This Privacy Policy explains how we collect, use, and protect your personal information. By using our website, you consent to the practices described in this policy.</p>

            <h2>Information We Collect</h2>
            <p>We may collect various types of information when you interact with our website. This may include your name, email address, phone number, location data, and browsing activity. We collect this information to understand your preferences and provide a better user experience.</p>

            <h2>How We Use Your Information</h2>
            <p>The information we collect is used to improve our services and enhance your interaction with our website. We may use your email address to send you updates, promotions, and important announcements. Your data helps us personalize your experience and tailor our content to your interests.</p>

            <h2>Sharing Your Information</h2>
            <p>We respect your privacy and do not sell, trade, or rent your personal information to third parties for marketing purposes. However, we may share your information with trusted service providers who assist us in operating our website, managing customer interactions, and conducting business activities.</p>

            <h2>Cookies and Tracking</h2>
            <p>We use cookies and similar tracking technologies to gather information about your browsing behavior. Cookies are small text files stored on your device that help us analyze website traffic, understand user preferences, and provide a customized experience. You can modify your browser settings to block cookies or receive alerts when cookies are being sent.</p>

            <h2>Security Measures</h2>
            <p>We take your data security seriously. We implement industry-standard security measures to protect your information from unauthorized access, alteration, or disclosure. While we strive to safeguard your data, no online transmission or storage can guarantee complete security. Please use caution when sharing personal information online.</p>

            <h2>Your Choices</h2>
            <p>You have the right to control the information you provide to us. You can update your preferences, unsubscribe from emails, or request the deletion of your account by contacting us. However, certain essential information may be required to access specific features of our website.</p>

            <h2>Children's Privacy</h2>
            <p>Our website is not intended for children under the age of 13. We do not knowingly collect personal information from individuals under 13 years of age. If you are a parent or guardian and believe your child has provided us with personal information, please contact us, and we will take appropriate steps to remove such information.</p>

            <h2>Changes to This Policy</h2>
            <p>We may update our Privacy Policy from time to time to reflect changes in our practices or legal requirements. Any updates will be posted on this page, and the date of the latest revision will be indicated. We encourage you to review this policy periodically to stay informed about how we protect your information.</p>

            <h2>Contact Us</h2>
            <p>If you have any questions, concerns, or requests regarding our Privacy Policy or the handling of your personal information, please contact us at privacy@tranquil.com.</p>
        </div>
    </div>
);

export default PrivacyPolicy;
