import React from 'react';
import styles from './SignContractSeg.module.css';

const Button = ({ iconSrc, text, onClick }) => (
    <button className={styles['action-button']} onClick={onClick}>
        <img src={iconSrc} className={styles['button-icon']} alt={text} />
        <span className={styles['button-text']}>{text}</span>
    </button>
);

const SignContractPanel = ({ onDownload, onUpload, isLoading }) => {
    return (
        <div className={styles['sign-contract-panel']}>
            <div className={styles['buttons-group']}>
                <Button iconSrc="/icons/internal/signcontract/document.svg" text="Download Contract" onClick={onDownload} />
                <Button iconSrc="/icons/internal/signcontract/cloud_upload.svg" text="Upload Signed Contract" onClick={onUpload} />
            </div>
            {isLoading ? (
                <div className={styles['loading-container']}>
                    <div className={styles['loading-indicator']}></div>
                </div>
            ) : null}
        </div>
    );
};

export default SignContractPanel;