import React from 'react';

// Components
import TopBar from '../../components/external/signin/topbar/TopBar';
import SignInMain from '../../components/external/signin/main/SignInMain';

const SignIn = () => {
    return (
        <div className="app-container">
            <TopBar />
            <SignInMain />
        </div>
    );
};

export default SignIn;