import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import TopBar from '../../components/external/getstarted/topbar/TopBar';
import Main from '../../components/external/getstarted/paths/main.js';
import { FormProvider } from '../../components/external/getstarted/paths/FormContext.js';

// Sponsor Pages
import SponsorPage1 from '../../components/external/getstarted/paths/sponsor/page1.js';
import SponsorPage2 from '../../components/external/getstarted/paths/sponsor/page2.js';
import SponsorPage3 from '../../components/external/getstarted/paths/sponsor/page3.js';

// Path 2 Pages
import InvestorPage1 from '../../components/external/getstarted/paths/investor/page1.js';
import InvestorPage2 from '../../components/external/getstarted/paths/investor/page2.js';
import InvestorPage3 from '../../components/external/getstarted/paths/investor/page3.js';
import InvestorPage4 from '../../components/external/getstarted/paths/investor/page4.js';
import InvestorPage5 from '../../components/external/getstarted/paths/investor/page5.js';
import InvestorPage6 from '../../components/external/getstarted/paths/investor/page6.js';

// Privacy and Terms
import PrivacyPolicy from '../../screens/dummyscreens/PrivacyPolicy';
import TermsAndConditions from '../../screens/dummyscreens/TermsAndConditions';

const GetStarted = () => {
    const location = useLocation();
    const [currentIndex, setCurrentIndex] = useState(0);

    // Define paths for each route
    const SponsorRoutes = [
        'thebasics',
        'investmentinterests',
        'accountdetails'
    ];

    const InvestorRoutes = [
        'thebasics',
        'investmentlocation',
        'riskcoverage',
        'coverageamount',
        'scalethresholds',
        'accountdetails'
    ];

    useEffect(() => {
        let newIndex = -1;

        if (location.pathname.startsWith('/getstarted/inv/')) {
            const path = location.pathname.split('/getstarted/inv/')[1];
            newIndex = InvestorRoutes.indexOf(path);
        } else if (location.pathname.startsWith('/getstarted/alt/')) {
            const path = location.pathname.split('/getstarted/alt/')[1];
            newIndex = SponsorRoutes.indexOf(path);
        }

        setCurrentIndex(newIndex);
    }, [location]);

    let totalPaths = InvestorRoutes.length;
    if (location.pathname.startsWith('/getstarted/alt/')) {
        totalPaths = SponsorRoutes.length;
    }

    return (
        <div className="app-container">
            <FormProvider>
                <TopBar currentIndex={currentIndex} totalPaths={totalPaths} />
                <Routes>
                    <Route path="/" element={<Main />} />
                    <Route path="/inv/*" element={<InvestorPages />} />
                    <Route path="/alt/*" element={<SponsorPages />} />
                </Routes>
            </FormProvider>
        </div>
    );
};

const SponsorPages = () => {
    return (
        <Routes>
            <Route path="/thebasics" element={<SponsorPage1 />} />
            <Route path="/investmentinterests" element={<SponsorPage2 />} />
            <Route path="/accountdetails" element={<SponsorPage3 />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        </Routes>
    );
};

const InvestorPages = () => {
    return (
        <Routes>
            <Route path="/thebasics" element={<InvestorPage1 />} />
            <Route path="/investmentlocation" element={<InvestorPage2 />} />
            <Route path="/riskcoverage" element={<InvestorPage3 />} />
            <Route path="/coverageamount" element={<InvestorPage4 />} />
            <Route path="/scalethresholds" element={<InvestorPage5 />} />
            <Route path="/accountdetails" element={<InvestorPage6 />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        </Routes>
    );
};

export default GetStarted;
