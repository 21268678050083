import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { subscribeToAllContracts } from './fetchUserDataService.js';
import { setAllContracts } from '../../redox/reducers/contractsReducer.js';

const useSubscribeToAllContracts = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const unsubscribe = subscribeToAllContracts((contracts) => {
            console.log("CONTRACTS");
            console.log(contracts);
            dispatch(setAllContracts(contracts));
        });

        // Unsubscribe when the component unmounts
        return () => unsubscribe();
    }, [dispatch]);
};

export default useSubscribeToAllContracts;