import React from 'react';
import styles from './Portfolio.module.css';
import Segment from '../../segment/Segment';

const SponsorPortfolio = () => {
    return (
        <div className={styles["body"]}>
            <div className={styles["page-header"]}>Portfolio</div>
            <div className={styles["page-segments"]}>
                <Segment title="Overview" segmentBody="This page is currently underdevelopment 🚀"></Segment>
            </div>
        </div>
    );
};

export default SponsorPortfolio;
