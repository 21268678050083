import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import ProgressiveImage from 'react-progressive-image-loading';
import styles from './WhatWeDo.module.css';

function TextSegment({ title, heading, description }) {
    return (
        <>
            <h1 className={styles['section-title']}>{title}</h1>
            <h2 className={styles['section-heading']} dangerouslySetInnerHTML={{ __html: heading }}></h2>
            <p className={styles['section-description']} dangerouslySetInnerHTML={{ __html: description }}></p>
        </>
    );
}

function LearnMoreButton() {
    const downloadPDF = () => {
        const dayOfMonth = new Date().getDate();
        const expectedPassword = `tranquil${dayOfMonth}`;
        const password = prompt('Please enter the password to download the whitepaper.');

        if (password === expectedPassword) {
            const link = document.createElement('a');
            link.href = '/docs/whitepaper.pdf';
            link.download = 'whitepaper.pdf';
            link.click();
        } else {
            alert('Incorrect password. Please try again.');
        }
    };

    return (
        <button className={styles['learn-more-button']} onClick={downloadPDF}>
            <span className={styles['underline']}>Learn More</span>
            <FontAwesomeIcon icon={faArrowRight} />
        </button>
    );
}

function ImageComponent({ previewSrc, mainSrc, altText }) {
    return (
        <ProgressiveImage
            preview={previewSrc}
            src={mainSrc}
            render={(src, style) => <img src={src} style={style} alt={altText} />}
        />
    );
}

function WhatWeDo() {
    return (
        <section className={styles['what-we-do']}>
            <div className={styles['content-section']}>
                <TextSegment
                    title="WHAT WE DO"
                    heading="Securing global <br /> investment projects."
                    description={`We leverage the power of AI and blockchain <br />
                            technology in combination with real-time and <br /> historical data to turn risk into a tradable asset.`}
                />
                <LearnMoreButton />
            </div>
            <div className={styles['image-section']}>
                <div className={styles['image-container']}>
                    <ImageComponent
                        previewSrc={`${process.env.PUBLIC_URL}/images/launch/watermelon-stall-small.jpg`}
                        mainSrc={`${process.env.PUBLIC_URL}/images/launch/watermelon-stall.png`}
                        altText="Watermelon Stall"
                    />
                </div>
            </div>
        </section>
    );
}

export default WhatWeDo;
