import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import TextField from '../../../../shared/textfield/Textfield';
import sharedStyles from './path2.module.css';
import styles from './page4.module.css';
import Slider from '../../../../shared/slider/Slider';
import { FormContext } from '../FormContext'; // Make sure to import from the correct path

const Path2Page4 = () => {
    const navigate = useNavigate();
    const { formData, setFormData } = useContext(FormContext); // Access the shared form data and setter function

    const [netValue, setNetValue] = useState(formData.netValue);
    const [currency, setCurrency] = useState(formData.currency);
    const [coverage, setCoverage] = useState(formData.coverage);

    // State to keep track of the validity of the net value text field
    const [isNetValueValid, setIsNetValueValid] = useState(netValue.trim() !== '');

    const handleNext = () => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            netValue,
            currency,
            coverage,
        }));

        // We need to check how many risks they have selected
        // if they have selected 1 risk, we do not need to create a ratio
        // if they have bundled risks, then they need to choose how they scale the risks
        const riskBooleanStats = Object.values(formData.risks);
        const countTrueValues = riskBooleanStats.reduce((count, currentValue) => {
            return count + (currentValue === true ? 1 : 0);
        }, 0);

        // Skip scaling thresholds if there is only one selected
        if (countTrueValues > 1) {
            navigate('/getstarted/inv/scalethresholds');
        } else if (countTrueValues === 1) {
            // We need to set the weighting to 1 for the single risk covered
            for (const risk in formData.risks) {
                if (formData.risks.hasOwnProperty(risk) && formData.risks[risk] === true) {
                    formData.risksThreshold[risk] = 100;
                }
            }

            navigate('/getstarted/inv/accountdetails');
        }
    };

    const handleBack = () => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            netValue,
            currency,
            coverage,
        }));
        navigate('/getstarted/inv/riskcoverage');
    };

    const handleNetValueChange = (value) => {
        setNetValue(value);
        setIsNetValueValid(value.replace(/\D/g, '').trim() !== '');
    };

    const handleCoverageChange = (value) => {
        setCoverage(value);
    };

    return (
        <div className={sharedStyles['main-container']}>
            <div className={sharedStyles['page-header-text']}>
                <p className={sharedStyles['page-header-tiny-text']}>Quick Start</p>
                <h2 className={sharedStyles['page-header-descriptor-text']}>Enter Investment Value and Coverage Amount</h2>
            </div>
            <div className={styles['body']}>
                <div className={styles['text-fields-container']}>
                    <div className={styles['text-field-sizer']}>
                        <TextField
                            defaultLabel="Enter the net value"
                            focusLabel="NET VALUE"
                            value={netValue}
                            onChange={handleNetValueChange}
                            validation={(value) => (value.trim() === '' ? 'Net value cannot be empty' : null)}
                            showErrors={true}
                            onInputChange={() => { }}
                            numberFormat={true}
                            currency={true}
                        />
                    </div>
                    <div className={styles['text-field-sizer']}>
                        <TextField
                            defaultLabel="CURRENCY" // Enter the currency
                            focusLabel="CURRENCY"
                            value={currency}
                            onChange={setCurrency}
                            validation={(value) => (value.trim() === '' ? 'Currency cannot be empty' : null)}
                            showErrors={true}
                            onInputChange={() => { }}
                            editable={false}
                        />
                    </div>
                </div>
                <div className={styles['slider-container']}>
                    <Slider label="COVERAGE" maxValue={netValue} divisions={4} initial={coverage} onChange={handleCoverageChange} />
                </div>
            </div>
            <div className={sharedStyles['button-group']}>
                <button
                    className={`${sharedStyles['next-button']} ${isNetValueValid ? '' : sharedStyles['disabled']}`}
                    onClick={handleNext}
                    disabled={!isNetValueValid}
                >
                    <strong>Next</strong>
                </button>
                <button className={sharedStyles['back-button']} onClick={handleBack}>
                    <FontAwesomeIcon icon={faChevronLeft} className={sharedStyles['chevron-icon']} />
                    <strong>Back</strong>
                </button>
            </div>
        </div>
    );
};

export default Path2Page4;
