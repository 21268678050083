import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faCheck } from '@fortawesome/free-solid-svg-icons';
import styles from './page3.module.css'; // Updated import
import sharedStyles from './path2.module.css';
import { FormContext } from '../FormContext';

const iconsMap = {
    Weather: 'weather.svg',
    Political: 'political.svg',
    Interest: 'interest.svg',
    Cyber: 'cyber.svg',
    'Foreign Exchange': 'exchange.svg',
};

const Square = ({ title, state, onChange }) => {
    const isAvailable = state !== null;
    const isChecked = state === true;
    const iconFileName = iconsMap[title];

    const handleChange = () => {
        if (isAvailable) {
            onChange(!isChecked);
        }
    };
    return (
        <div className={styles['square-container']}>
            <div className={`${styles.square} ${isAvailable ? '' : styles.unavailable}`} onClick={handleChange}>
                <div className={styles['square-content']}>
                    <div className={styles.icon}>
                        <img src={`/icons/riskcoverage/${iconFileName}`} alt={title} />
                    </div>
                    <div className={styles.title}>{title}</div>
                </div>
            </div>
            {isAvailable && (
                <div className={styles.checkbox}>
                    <label className={styles.checkboxButton}>
                        <input
                            type="checkbox"
                            id={`checkbox-${title}`}
                            name={`checkbox-${title}`}
                            value={title}
                            checked={isChecked}
                            onChange={() => onChange(!isChecked)}
                        />
                        <span className={styles.checkboxIconWrapper}>
                            {isChecked && <FontAwesomeIcon icon={faCheck} className={styles.check} />}
                        </span>
                    </label>
                </div>
            )}
            {!isAvailable && <div className={styles['unavailable-text']}>Unavailable</div>}
        </div>
    );
};

const Path2Page3 = () => {
    const navigate = useNavigate();
    const { formData, setFormData } = useContext(FormContext);
    const [mapData, setMapData] = useState(formData.risks);
    const [isAtLeastOneRiskSelected, setIsAtLeastOneRiskSelected] = useState(false);

    const handleSquareChange = (key, value) => {
        setMapData((prevMapData) => ({
            ...prevMapData,
            [key]: value,
        }));
    };

    const handleNext = () => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            risks: mapData,
        }));
        navigate('/getstarted/inv/coverageamount');
    };

    const handleBack = () => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            risks: mapData,
        }));
        navigate('/getstarted/inv/investmentlocation');
    };

    useEffect(() => {
        const selectedRisks = Object.values(mapData).some((value) => value === true);
        setIsAtLeastOneRiskSelected(selectedRisks);
    }, [mapData]);

    return (
        <div className={sharedStyles['main-container']}>
            <div className={sharedStyles['page-header-text']}>
                <p className={sharedStyles['page-header-tiny-text']}>Quick Start</p>
                <h2 className={sharedStyles['page-header-descriptor-text']}>Select Desired Risk Coverage</h2>
            </div>
            <div className={styles['squares-container']}>
                {Object.entries(mapData).map(([key, value], index) => (
                    <Square key={index} title={key} state={value} onChange={(newValue) => handleSquareChange(key, newValue)} />
                ))}
            </div>
            <div className={sharedStyles['button-group']}>
                <button
                    className={`${sharedStyles['next-button']} ${isAtLeastOneRiskSelected ? '' : sharedStyles['disabled']}`}
                    onClick={handleNext}
                    disabled={!isAtLeastOneRiskSelected}
                >
                    <strong>Next</strong>
                </button>
                <button className={sharedStyles['back-button']} onClick={handleBack}>
                    <FontAwesomeIcon icon={faChevronLeft} className={sharedStyles['chevron-icon']} />
                    <strong>Back</strong>
                </button>
            </div>
        </div>
    );
};

export default Path2Page3;
