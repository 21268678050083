export const setAllContracts = (contracts) => {
    return {
        type: 'SET_ALL_CONTRACTS',
        payload: contracts,
    };
};

const contractsReducer = (state = { pending: [], active: [] }, action) => {
    switch (action.type) {
        case 'SET_ALL_CONTRACTS':
            return {
                pending: action.payload.filter(contract => contract.status === 'Pending'),
                active: action.payload.filter(contract => contract.status === 'Active')
            };
        case 'REMOVE_CONTRACT':
            return {
                pending: state.pending.filter(contract => contract.id !== action.payload),
                active: state.active.filter(contract => contract.id !== action.payload)
            };
        default:
            return state;
    }
};

export default contractsReducer;
