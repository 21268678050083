import React from 'react';
import styles from './SideBar.module.css';
import SideBarButton from './sidebarbutton/SideBarButton.js';

const SideBar = ({ collapsed, activeButton, onClick, accountType = "investor" }) => {
    const intestorMenuItems = [
        {
            to: '/dashboard',
            iconSrc: '/icons/internal/sidebar/dashboard.svg',
            label: 'Dashboard',
            key: 'Dashboard',
        },
        {
            to: '/contracts',
            iconSrc: '/icons/internal/sidebar/contracts.svg',
            label: 'Contracts',
            key: 'Contracts',
        },
        {
            to: '/funds',
            iconSrc: '/icons/internal/sidebar/funds.svg',
            label: 'Funds',
            key: 'Funds',
        },
        {
            to: '/news',
            iconSrc: '/icons/internal/sidebar/newsandupdates.svg',
            label: 'News & Updates',
            key: 'News & Updates',
        },
    ];

    const intestorToolItems = [
        {
            to: '/support',
            iconSrc: '/icons/internal/sidebar/support.svg',
            label: 'Support',
            key: 'Support',
        },
        {
            to: '/chat',
            iconSrc: '/icons/internal/sidebar/livechat.svg',
            label: 'Live Chat',
            key: 'Live Chat',
        },
        {
            to: '/settings',
            iconSrc: '/icons/internal/sidebar/settings.svg',
            label: 'Settings',
            key: 'Settings',
        },
    ];

    const sponsorMenuItems = [
        {
            to: '/dashboard',
            iconSrc: '/icons/internal/sidebar/dashboard.svg',
            label: 'Dashboard',
            key: 'Dashboard',
        },
        {
            to: '/portfolio',
            iconSrc: '/icons/internal/sidebar/portfolio.svg',
            label: 'Portfolio',
            key: 'Portfolio',
        },
        {
            to: '/investments',
            iconSrc: '/icons/internal/sidebar/investments.svg',
            label: 'Investments',
            key: 'Investments',
        },
        {
            to: '/impact',
            iconSrc: '/icons/internal/sidebar/impact.svg',
            label: 'Impact',
            key: 'Impact',
        },
        {
            to: '/funds',
            iconSrc: '/icons/internal/sidebar/funds.svg',
            label: 'Funds',
            key: 'Funds',
        },
        {
            to: '/news',
            iconSrc: '/icons/internal/sidebar/newsandupdates.svg',
            label: 'News & Updates',
            key: 'News & Updates',
        },
    ];

    const sponsorToolItems = [
        {
            to: '/support',
            iconSrc: '/icons/internal/sidebar/support.svg',
            label: 'Support',
            key: 'Support',
        },
        {
            to: '/chat',
            iconSrc: '/icons/internal/sidebar/livechat.svg',
            label: 'Live Chat',
            key: 'Live Chat',
        },
        {
            to: '/settings',
            iconSrc: '/icons/internal/sidebar/settings.svg',
            label: 'Settings',
            key: 'Settings',
        },
    ];

    return (
        <div className={`${styles['sidebar']} ${collapsed ? styles['collapsed'] : ''}`}>
            <div className={styles['menu-group']}>
                <div className={`${styles['menu-heading']} ${collapsed ? styles['collapsed'] : ''}`}>
                    {collapsed ? 'M' : 'MENU'}
                </div>
                {(accountType === "investor" ? intestorMenuItems : sponsorMenuItems).map((item) => (
                    <SideBarButton
                        key={item.key}
                        to={item.to}
                        iconSrc={item.iconSrc}
                        label={item.label}
                        isActive={activeButton === item.key}
                        onClick={onClick}
                        collapsed={collapsed}
                    />
                ))}
            </div>

            <div className={styles['tools-group']}>
                <div className={`${styles['menu-heading']} ${collapsed ? styles['collapsed'] : ''}`}>
                    {collapsed ? 'T' : 'TOOLS'}
                </div>
                {(accountType === "investor" ? intestorToolItems : sponsorToolItems).map((item) => (
                    <SideBarButton
                        key={item.key}
                        to={item.to}
                        iconSrc={item.iconSrc}
                        label={item.label}
                        isActive={activeButton === item.key}
                        onClick={onClick}
                        collapsed={collapsed}
                    />
                ))}
            </div>
        </div>
    );
};

export default SideBar;
