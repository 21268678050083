import React from 'react';
import ReactPDF from '@react-pdf/renderer';

const styles = ReactPDF.StyleSheet.create({
    page: {
        flexDirection: 'column',
        padding: 50,
    },
    logo: {
        width: '20%',
        marginBottom: 40,
        alignSelf: 'center',
    },
    title: {
        textAlign: 'Left',
        fontSize: 18,
        fontWeight: 'bold',
    },
    section: {
        fontSize: 12,
        marginBottom: 10,
    },
    heading: {
        fontSize: 14,
        fontWeight: 'bold',
        marginBottom: 5,
        marginTop: 20,
    },
    list: {
        paddingLeft: 15,
        fontSize: 12,
    },
    listItem: {
        fontSize: 12,
        marginBottom: 5,
    },
    signature: {
        marginTop: 280,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    date: {
        fontSize: 12,
    },
});

const ContractPDF = ({ contract, protectionCost }) => (
    <ReactPDF.Document>
        <ReactPDF.Page style={styles.page}>
            <ReactPDF.Image src={"/images/tranquil_logo.png"} preserveAspectRatio="xMidYMid meet" style={styles.logo} />
            <ReactPDF.Text style={styles.title}>Contract Summary</ReactPDF.Text>
            <ReactPDF.Text style={styles.heading}>1. Project Details</ReactPDF.Text>
            <ReactPDF.Text style={styles.section}>Project Name: {contract.project_name}</ReactPDF.Text>
            <ReactPDF.Text style={styles.section}>Investment Address: {contract.investment_address}</ReactPDF.Text>
            {contract.available_risks.length === 1 ? <ReactPDF.Text style={styles.section}>Non-bundled Risk: {contract.available_risks[0]}</ReactPDF.Text> : <ReactPDF.Text style={styles.section}>Bundled Risks: {contract.available_risks.join(", ")}</ReactPDF.Text>}
            <ReactPDF.Text style={styles.section}>Residual Term: {contract.residual_term} days</ReactPDF.Text>
            <ReactPDF.Text style={styles.section}>Net Value: ${contract.net_value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</ReactPDF.Text>
            <ReactPDF.Text style={styles.section}>Coverage: {contract.coverage}%</ReactPDF.Text>
            <ReactPDF.Text style={styles.section}>Coverage Value: ${Math.round(contract.net_value * contract.coverage / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</ReactPDF.Text>
            <ReactPDF.Text style={styles.section}>Protection Cost: ${protectionCost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</ReactPDF.Text>
            <ReactPDF.Text style={styles.heading}>2. Terms and Conditions</ReactPDF.Text>
            <ReactPDF.View style={styles.list}>
                <ReactPDF.Text style={styles.listItem}>2.1 Coverage is subject to acceptance of Tranquil's policies and procedures.</ReactPDF.Text>
                <ReactPDF.Text style={styles.listItem}>2.2 Please consult the full legal document for all terms and conditions.</ReactPDF.Text>
            </ReactPDF.View>
            <ReactPDF.View style={styles.signature}>
                <ReactPDF.Text style={styles.section}>Signature: ___________________________</ReactPDF.Text>
                <ReactPDF.Text style={styles.date}>Date: {new Date().toLocaleDateString()}</ReactPDF.Text>
            </ReactPDF.View>
        </ReactPDF.Page>
    </ReactPDF.Document>
);

export default ContractPDF; 