import React from 'react';
import styles from './Spread.module.css';

const Spread = ({ names, opacities }) => {
    return (
        <div className={styles['spread-container']}>
            {names.map((name, index) => (
                <div
                    className={styles['background']}
                    style={{
                        background: `linear-gradient(rgba(255, 255, 255, ${1 -
                            opacities[index]}), rgba(255, 255, 255, ${1 - opacities[index]})), var(--theme-blue)`
                    }}
                    key={index}
                >
                    <div className={styles['display-window']}>{name}</div>
                </div>
            ))}
        </div>
    );
};

export default Spread;
