import React, { useState, useEffect } from 'react';

// Styling
import './App.css';
import './shared-styling.css';

// Fonts
import './fonts/grotesk/Kostic - Roc Grotesk Regular.otf';
import './fonts/grotesk/Kostic - Roc Grotesk Thin.otf';
import './fonts/grotesk/Kostic - Roc Grotesk Medium.otf';

// Routing
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// Main Screens
import LaunchScreen from './screens/external/Launch';
import SignIn from './screens/external/SignIn';
import GetStarted from './screens/external/GetStarted';
import Internal from './screens/internal/Internal';
import MeetJoaquim from './screens/dummyscreens/MeetJoaquim';

// Firebase
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase/configs.js';

const App = () => {
  const [loggedIn, setLoggedIn] = useState(false); // State to track user login status

  // Check if the user is logged in on component mount
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is logged in
        setLoggedIn(true);
      } else {
        // User is not logged in
        setLoggedIn(false);
      }
    });

    // Clean up the subscription when the component unmounts
    return () => unsubscribe();
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/*" element={loggedIn ? <Internal /> : <LaunchScreen />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/getstarted/*" element={<GetStarted />} />
        <Route path="/meetjoaquim" element={<MeetJoaquim />} />
        <Route path="/meetjoaquim" element={<MeetJoaquim />} />
      </Routes>
    </Router>
  );
};

export default App;