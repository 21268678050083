import { SET_CONTRACTS_OVERVIEW } from '../actions/overviewActions';

const initialState = {
  contracts_overview: null,
};

const overviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CONTRACTS_OVERVIEW:
      return {
        ...state,
        contracts_overview: action.payload,
      };
    default:
      return state;
  }
};

export default overviewReducer;