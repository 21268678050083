import React from 'react';
import styles from './TermsAndConditions.module.css';
import TopBar from './TopBar';

const TermsAndConditions = () => (
    <div className={styles['app-container']}>
        <TopBar></TopBar>
        <div className={styles['terms-container']}>
            <h1>Terms and Conditions</h1>
            <p>Welcome to our website. These terms and conditions outline the rules and regulations for the use of our website.</p>

            <h2>Intellectual Property Rights</h2>
            <p>Unless otherwise stated, we own the intellectual property rights for all content and materials on this website. You may access this content for your personal use, but you must not republish, sell, or distribute any of the content without our written consent.</p>

            <h2>User Conduct</h2>
            <p>By using our website, you agree not to engage in any conduct that could be considered harmful, disruptive, or unlawful. You must not use our website for any illegal purpose or violate any laws or regulations.</p>

            <h2>Disclaimer</h2>
            <p>Our website is provided "as is," and we make no representations or warranties of any kind, express or implied, regarding the content or functionality. We are not responsible for any errors or omissions, and we disclaim all liability for any loss or damage that may arise from using our website.</p>

            <h2>Limitation of Liability</h2>
            <p>In no event shall we be liable for any indirect, incidental, special, or consequential damages arising out of or in connection with the use of our website. We shall not be liable for any errors or delays in the content, or for any actions taken based on the content.</p>

            <h2>Privacy</h2>
            <p>Your privacy is important to us. We collect and process personal information in accordance with our Privacy Policy. By using our website, you consent to the collection and use of your information as described in the Privacy Policy.</p>

            <h2>Changes to Terms</h2>
            <p>We may update these terms and conditions at any time without notice. By continuing to use our website, you agree to be bound by the updated terms. It is your responsibility to review these terms regularly.</p>

            <h2>Contact Us</h2>
            <p>If you have any questions or concerns about these terms and conditions, please contact us at terms@example.com.</p>

            <p>Last updated: August 11, 2023</p>
        </div>
    </div>
);

export default TermsAndConditions;
