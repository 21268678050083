import { combineReducers } from 'redux';
import userReducer from './userReducer';
import overviewReducer from './overviewReducer';
import contractsReducer from './contractsReducer';

const rootReducer = combineReducers({
    user: userReducer,
    overview: overviewReducer,
    contracts: contractsReducer,
});

export default rootReducer;