import React, { useState, useEffect } from 'react';
import styles from './SignInMain.module.css';
import TextField from '../../../shared/textfield/Textfield.js';
import { useNavigate } from 'react-router-dom';
import { signin } from '../../../../firebase/services/accountServices.js'; // Import the signin function

const SignInMain = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showErrors, setShowErrors] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false); // New state variable to track loading

    // Basic validation function for the email field
    const emailValidation = (value) => {
        if (!value) {
            return 'Email address cannot be empty';
        } else if (!isValidEmail(value)) {
            return 'Invalid email address';
        } else {
            return null;
        }
    };

    // Basic validation function for the password field
    const passwordValidation = (value) => {
        if (!value) {
            return 'Password cannot be empty';
        } else {
            return null;
        }
    };

    const isValidEmail = (email) => {
        // Basic email format validation
        const emailRegex = /\S+@\S+\.\S+/;
        return emailRegex.test(email);
    };

    const validateForm = () => {
        const emailError = emailValidation(email);
        const passwordError = passwordValidation(password);
        setIsFormValid(!emailError && !passwordError);
    };

    const handleInputChange = () => {
        setShowErrors(true);
        validateForm();
    };

    useEffect(() => {
        validateForm();
    }, [email, password]); // Run validateForm whenever email or password changes

    const handleSignIn = async () => {
        console.log(email);
        console.log(password);

        setIsLoading(true); // Set the loading state to true

        const result = await signin(email, password);

        if (result.success) {
            console.log('SUCCESS');
            const user = result.user;
            console.log(user);
            setIsLoading(false); // Reset the loading state
            navigate('/');
        } else {
            console.log('ERROR');
            const errorMessage = result.message;
            console.log(errorMessage);
            setIsLoading(false); // Reset the loading state in case of error
        }
    };

    return (
        <div className={styles['signin-main']}>
            <div className={styles['signin-title-container']}>
                <p className={styles['signin-tiny-text']}>Sign in</p>
                <h2 className={styles['signin-welcome-text']}>Welcome Back</h2>
            </div>
            <div className={styles['textfield-container']}>
                <TextField
                    defaultLabel="Enter your email address"
                    focusLabel="EMAIL ADDRESS"
                    value={email}
                    onChange={setEmail}
                    validation={emailValidation}
                    showErrors={showErrors}
                    onInputChange={handleInputChange} // Use the common handleInputChange function
                />
                <TextField
                    defaultLabel="Enter your password"
                    focusLabel="PASSWORD"
                    value={password}
                    onChange={setPassword}
                    validation={passwordValidation}
                    showErrors={showErrors}
                    onInputChange={handleInputChange} // Use the common handleInputChange function
                    obscureText={true}
                />
            </div>
            {isLoading ? (
                <div className={styles['loading-container']}>
                    <div className={styles['loading-indicator']} />
                </div>
            ) : (
                <button
                    className={`${styles['signin-button']} ${isFormValid ? '' : styles.disabled}`}
                    disabled={!isFormValid || isLoading}
                    onClick={handleSignIn}
                >
                    Sign in
                </button>
            )}
        </div>
    );
};

export default SignInMain;