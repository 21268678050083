import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import styles from './Dropdown.module.css';

const Dropdown = ({ options, defaultLabel, selectedOption, onChange }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const dropdownRef = useRef(null);

    const handleOptionClick = (option) => {
        onChange(option);
        setIsExpanded(false);
    };

    const handleDropdownToggle = () => {
        setIsExpanded((prevExpanded) => !prevExpanded);
    };

    useEffect(() => {
        const handleDocumentClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsExpanded(false);
            }
        };

        document.addEventListener('click', handleDocumentClick);

        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, []);

    return (
        <div ref={dropdownRef} className={styles.dropdown}>
            <div className={styles.dropdownHeader} onClick={handleDropdownToggle}>
                <div>
                    <div className={styles.dropdownLabel}>
                        {defaultLabel}
                    </div>
                    <div className={styles.dropdownSelected}>
                        <div className={styles.selectedContent}>
                            {selectedOption}
                        </div>
                    </div>
                    {isExpanded && (
                        <div className={styles.dropdownOptions}>
                            {options.map((option) => (
                                <div
                                    key={option}
                                    className={`${styles.dropdownOption} ${option === selectedOption ? styles.selectedOption : ''}`}
                                    onClick={(event) => {
                                        handleOptionClick(option);
                                        event.stopPropagation();
                                    }}
                                >
                                    {option}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <FontAwesomeIcon icon={faChevronDown} className={styles.chevronIcon} />
            </div>
        </div>
    );
};

export default Dropdown;
