import React from 'react';
import styles from './UseCase.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpandArrowsAlt } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const TitleComponent = () => (
    <div className={styles.header}>
        <h1 className={styles['use-case-title']}>USE CASE</h1>
        <h2 className={styles['tranquil-in-action']}>Tranquil in Action</h2>
    </div>
);

const TextComponent = () => (
    <div className={styles['left-content']}>
        <h3 className={styles['meet-joaquim']}>Meet Joaquim</h3>
        <p className={styles.description}>
            A sweet potato farmer who relies on regular rainfall to grow his crops.
            Joaquim needs a loan from his local bank to keep his business going
            after facing losses from pests. Tranquil Capital quantifies this risk to
            provide insurance for the bank so they can provide Joaquim the
            financial support he needs.
        </p>
    </div>
);

const ImageComponent = () => (
    <div className={styles['right-content']}>
        <div className={styles['image-container']}>
            <img src="/images/launch/joaquim.png" alt="Joaquim" className={styles['joaquim-image']} />
        </div>
    </div>
);

const ButtonComponent = ({ onButtonClick }) => (
    <div className={styles['read-more-container']}>
        <button className={styles['read-more-button']} onClick={onButtonClick}>
            Read More <FontAwesomeIcon icon={faExpandArrowsAlt} className={styles['expand-icon']} />
        </button>
    </div>
);

const ContentComponent = ({ onButtonClick }) => (
    <div className={styles.content}>
        <TextComponent />
        <ImageComponent />
        <ButtonComponent onButtonClick={onButtonClick} />
    </div>
);

const UseCase = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/meetjoaquim');
    };

    return (
        <div className={styles['use-case']}>
            <TitleComponent />
            <ContentComponent onButtonClick={handleClick} />
        </div>
    );
};

export default UseCase;
