import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import TextField from '../../../../shared/textfield/Textfield';
import sharedStyles from './path2.module.css'; // Import shared styles
import styles from './page2.module.css'; // Import page-specific styles
import { FormContext } from '../FormContext';

const Path2Page2 = () => {
    const navigate = useNavigate();
    const { formData, setFormData } = useContext(FormContext);
    const [address, setAddress] = useState(formData.investmentAddress);
    const [isFormValid, setIsFormValid] = useState(false);

    const handleNext = () => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            investmentAddress: address,
        }));
        navigate('/getstarted/inv/riskcoverage');
    };

    const handleBack = () => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            investmentAddress: address,
        }));
        navigate('/getstarted/inv/thebasics');
    };

    useEffect(() => {
        setIsFormValid(address.trim() !== '');
    }, [address]);

    return (
        <div className={sharedStyles['main-container']}>
            <div className={sharedStyles['page-header-text']}>
                <p className={sharedStyles['page-header-tiny-text']}>Quick Start</p>
                <h2 className={sharedStyles['page-header-descriptor-text']}>Enter the Investment Location</h2>
            </div>
            <div className={styles['body']}>
                <div className={styles['image-container']}>
                    <img src="/images/getstarted/address.png" alt="Investment Address" className={styles['image']} />
                </div>
                <div className={styles['text-field-sizer-large']}>
                    <TextField
                        defaultLabel="Enter your investment address"
                        focusLabel="ADDRESS"
                        value={address}
                        onChange={setAddress}
                        validation={(value) => (value.trim() === '' ? 'Investment address cannot be empty' : null)}
                        showErrors={true}
                        onInputChange={() => { }}
                    />
                </div>
            </div>
            <div className={sharedStyles['button-group']}>
                <button className={`${sharedStyles['next-button']} ${isFormValid ? '' : sharedStyles['disabled']}`} onClick={handleNext} disabled={!isFormValid}>
                    <strong>Next</strong>
                </button>
                <button className={sharedStyles['back-button']} onClick={handleBack}>
                    <FontAwesomeIcon icon={faChevronLeft} className={sharedStyles['chevron-icon']} />
                    <strong>Back</strong>
                </button>
            </div>
        </div>
    );
};

export default Path2Page2;
