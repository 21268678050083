import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import styles from './TopBar.module.css';

function TopBar() {
    return (
        <div className={styles['top-bar']}>
            <BrandText />
            <div className={styles['login-signup']}>
                <QuestionText />
                <SignupButton />
            </div>
        </div>
    );
}

function BrandText() {
    const navigate = useNavigate();

    const handleClickBrand = () => {
        navigate('/');
    };

    return (
        <div className={styles.brand} onClick={handleClickBrand}>
            <img src={"/images/logo_horizontal.png"} alt="Tranquil Capital" />
            {/* Tranquil Capital */}
        </div>
    );
}

function QuestionText() {
    return <button className={styles['question-text']}>New User?</button>;
}

function SignupButton() {
    const navigate = useNavigate();

    const handleClickSignup = () => {
        navigate('/getstarted');
    };

    return (
        <button className={styles['signup-button']} onClick={handleClickSignup}>
            Get Started <FontAwesomeIcon icon={faChevronRight} className={styles['signup-chevron']} />
        </button>
    );
}

export default TopBar;