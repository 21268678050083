import React from 'react';
import styles from './Table.module.css';

const Table = ({ headers, types, data, columnWidths, emptyMessage, onRowClick }) => {
    const getColumnStyle = (cellIndex, cellData) => {
        switch (types[cellIndex]) {
            case 'numbered':
                return { counterIncrement: 'table-counter', whiteSpace: 'nowrap' };
            case 'coloured':
                const colorMap = {
                    High: '#9F322B',
                    Medium: '#D4830A',
                    Low: '#3E7944',
                    'Not Triggered': '#1F358D',
                    Pending: '#D4830A',
                    'Ready to sign': '#3E7944',
                    'Active': '#3E7944',
                    'Claim Paid': '#3E7944',
                };
                const backgroundMap = {
                    High: '#F9E9E6',
                    Medium: '#F9F3E8',
                    Low: '#E9F4EB',
                    'Not Triggered': '#E2E8FF',
                    Pending: '#F9F3E8',
                    'Ready to sign': '#E9F4EB',
                    'Active': '#E9F4EB',
                    'Claim Paid': '#E9F4EB',
                };
                const color = colorMap[cellData] || 'grey';
                const backgroundColor = backgroundMap[cellData] || 'grey';
                return { color, backgroundColor: backgroundColor, textAlign: 'center' };
            default:
                return { fontWeight: 'normal' };
        }
    };

    if (data.length === 0) {
        return (
            <div className={styles['table-container']}>
                <table className={styles.table}>
                    <thead className={styles.headerText}>
                        <tr>
                            {headers.map((header, index) => (
                                <th key={index} className={styles.headerCell} style={columnWidths[index] ? { width: `${columnWidths[index]}%` } : null}>
                                    <div>{header}</div>
                                </th>
                            ))}
                        </tr>
                    </thead>
                </table>
                <p className={styles.noDataCell}>
                    {emptyMessage}
                </p>
            </div>
        );
    }

    return (
        <div className={styles['table-container']}>
            <table className={styles.table}>
                <thead className={styles.headerText}>
                    <tr>
                        {headers.map((header, index) => (
                            <th key={index} className={styles.headerCell} style={columnWidths[index] ? { width: `${columnWidths[index]}%` } : null}>
                                <div>{header}</div>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, rowIndex) => (
                        <tr key={rowIndex} className={styles['table-row']} onClick={() => onRowClick(rowIndex)}>
                            {row.map((cell, cellIndex) => (
                                <td
                                    key={cellIndex}
                                    className={styles.dataCell}
                                    style={columnWidths[cellIndex] ? { width: `${columnWidths[cellIndex]}%` } : null}
                                >
                                    <div style={types[cellIndex] === 'coloured' ? getColumnStyle(cellIndex, cell) : null} className={styles[types[cellIndex] === 'coloured' ? 'backing' : '']}>
                                        {types[cellIndex] === 'numbered' && (
                                            <span style={{ counterIncrement: 'table-counter' }}>
                                                {rowIndex + 1}{". "}
                                            </span>
                                        )}
                                        {cell}
                                    </div>
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Table;
