import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './SideBarButton.module.css';

const SideBarButton = ({ label, iconSrc, to, isActive, onClick, collapsed }) => {
    const handleClick = () => {
        onClick(label);
    };

    const [blueIconSrc, setBlueIconSrc] = useState(null);
    const navigate = useNavigate();
    const hexColor = getComputedStyle(document.documentElement).getPropertyValue('--theme-blue').trim();

    useEffect(() => {
        fetch(iconSrc)
            .then((response) => response.text())
            .then((data) => {
                const activeColor = isActive ? hexColor : 'black';
                const inactiveColor = isActive ? 'black' : hexColor;

                const modifiedSvgContent = data
                    .replaceAll(`fill="${inactiveColor}"`, `fill="${activeColor}"`);

                setBlueIconSrc('data:image/svg+xml;charset=utf-8,' + encodeURIComponent(modifiedSvgContent));
            })
            .catch((error) => {
                console.error('Error loading SVG:', error);
            });
    }, [iconSrc, isActive, hexColor]);

    const handleNavigation = () => {
        navigate(to);
    };

    return (
        <div
            className={`${styles['sidebar-button']} ${isActive ? styles['active'] : ''}`}
            onClick={() => {
                handleNavigation();
                handleClick();
            }}
        >
            <div className={styles['button-content']}>
                <div className={`${styles['active-indicator']} ${isActive ? styles['active'] : ''} ${collapsed ? styles['collapsed'] : ''}`} />
                <div className={`${styles['highlight-content']} ${isActive ? styles['active'] : ''} ${collapsed ? styles['collapsed'] : ''}`}>
                    <div className={`${styles['icon-container']} ${collapsed ? styles['collapsed'] : ''}`}>
                        <img src={isActive ? blueIconSrc : iconSrc} alt={label} className={styles['icon']} />
                    </div>
                    {!collapsed && <div className={styles['label']}>{label}</div>}
                </div>
            </div>
        </div>
    );
};

export default SideBarButton;