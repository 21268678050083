import React from 'react';
import styles from './Alerts.module.css';

const Alerts = ({ alerts }) => {
    const getAlertColor = (warningValue) => {
        switch (warningValue) {
            case 3:
                return '#FEEBEB';
            case 2:
                return '#FEEBCB';
            case 1:
                return '#EFEDBD';
            case 0:
                return '#BCE4BD';
            default:
                return 'transparent';
        }
    };

    const getLineColor = (warningValue) => {
        switch (warningValue) {
            case 3:
                return '#E53E3E';
            case 2:
                return '#DD6B20';
            case 1:
                return '#ECE53B';
            case 0:
                return '#31A035';
            default:
                return 'transparent';
        }
    };

    return (
        <div className={styles.alertsContainer}>
            {Object.keys(alerts).map((timePeriod) => (
                <div key={timePeriod} className={styles.timePeriod}>
                    <h4 className={styles.timePeriodTitle}>{timePeriod}</h4>
                    {alerts[timePeriod].map((alert, index) => (
                        <div
                            key={index}
                            className={styles.alert}
                            style={{
                                backgroundColor: getAlertColor(alert[1]),
                                borderLeft: `4px solid ${getLineColor(alert[1])}`
                            }}
                        >
                            <h5 className={styles.alertTitle}>{alert[0]}</h5>
                            <p className={styles.alertDescription}>{alert[2]}</p>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};

export default Alerts;