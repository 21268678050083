import React, { useEffect, useState } from 'react';
import styles from './Overview.module.css';

const Overview = ({ srcs, titles, values }) => {
    const statCardsData = srcs.map((src, index) => ({
        src,
        title: titles[index],
        value: values[index],
    }));

    return (
        <div className={styles.container}>
            {statCardsData.map((data, index) => (
                <React.Fragment key={index}>
                    <div className={styles['stat-card']}>
                        <div className={styles['circle']}>
                            <IconWithWhiteColor iconSrc={data.src} />
                        </div>
                        <div className={styles['content']}>
                            <div className={styles['value']}>{data.value}</div>
                            <div className={styles['descriptor']}>{data.title}</div>
                        </div>
                    </div>
                    {index !== statCardsData.length - 1 && <div className={styles['vertical-line']}></div>}
                </React.Fragment>
            ))}
        </div>
    );
};

const IconWithWhiteColor = ({ iconSrc }) => {
    const [whiteIconSrc, setWhiteIconSrc] = useState(null);

    useEffect(() => {
        fetch(iconSrc)
            .then((response) => response.text())
            .then((data) => {
                const modifiedSvgContent = data.replaceAll('fill="black"', 'fill="white"');
                setWhiteIconSrc('data:image/svg+xml;charset=utf-8,' + encodeURIComponent(modifiedSvgContent));
            })
            .catch((error) => {
                console.error('Error loading SVG:', error);
            });
    }, [iconSrc]);

    return <img src={whiteIconSrc} alt="Icon" className={styles['icon']} />;
};

export default Overview;
