import React, { useState } from 'react';
import styles from './Checkboxes.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const Checkboxes = ({ list, onChange, color, single = false, startOn = false }) => {
    const initialState = {};
    list.forEach(item => {
        initialState[item.text] = startOn;
    });

    const [itemStates, setItemStates] = useState(initialState);

    const handleToggle = (item) => {
        const updatedStates = {
            ...itemStates,
            [item.text]: !itemStates[item.text],
        };

        setItemStates(updatedStates);
        const areAllChecked = Object.values(updatedStates).every((isChecked) => isChecked) && (Object.keys(updatedStates).length === 2 || single);

        onChange(areAllChecked);
    };

    return (
        <div className={styles.checkboxesContainer}>
            {list.map((item, index) => (
                <label key={index} className={styles.checkboxButton}>
                    <input
                        type="checkbox"
                        id={`checkbox-${index}`}
                        name={`checkbox-${index}`}
                        value={item.text}
                        checked={itemStates[item.text] || false}
                        onChange={() => handleToggle(item)}
                    />
                    <span
                        className={`${styles.checkboxIconWrapper} ${itemStates[item.text] ? styles.checked : ''}`}
                        style={itemStates[item.text] ? { backgroundColor: color, borderColor: color } : {}}
                    >
                        {itemStates[item.text] ? <FontAwesomeIcon icon={faCheck} className={styles['check']} /> : null}
                    </span>
                    <span dangerouslySetInnerHTML={{ __html: item.html || item.text }} />
                </label>
            ))}
        </div>
    );
};

export default Checkboxes;
