import React from 'react';
import styles from './Dashboard.module.css';
import Segment from '../../segment/Segment';
import Overview from '../../../../shared/internal/overview/Overview.js';
import Table from '../../../../shared/internal/table/Table.js';
import { useSelector } from 'react-redux';
import useSubscribeToAllContracts from '../../../../../firebase/services/useSubscribeToAllContracts.js';
import { useNavigate } from 'react-router-dom';

const InvestorDashboard = () => {
    // Navigation functionality
    const navigate = useNavigate();

    // Pending contracts table data
    const headers = ['Code', 'Project Name', 'Coverage Amount', 'Residual Term', 'Status'];
    const types = ['numbered', 'normal', 'normal', 'normal', 'coloured'];
    const columnWidths = ['1', '1', '1', '1', '1'];

    // Overview design and value data
    const srcs = [
        '/icons/internal/overviews/contracts.svg',
        '/icons/internal/overviews/coverage.svg',
        '/icons/internal/overviews/payouts.svg',
        '/icons/internal/overviews/compensation.svg',
    ];

    // Retrieve the contracts from the Redux store
    const contracts = useSelector((state) => state.contracts);
    const contractsOverview = useSelector((state) => state.overview.contracts_overview);

    // Call the custom hook to subscribe to all contracts
    useSubscribeToAllContracts();

    // Map data for pending contracts
    const pendingData = mapContractData(contracts.pending);

    const titles = [
        'Active Contracts',
        'Total Coverage Value',
        'Net Claim Payouts Value',
        'Compensation Rate',
    ];

    const values = [
        contractsOverview ? contractsOverview.active_contracts : contracts.active.length,
        contractsOverview ? `$${contractsOverview.total_coverage_value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}K` : '$0K',
        contractsOverview ? `$${contractsOverview.net_claim_payouts_value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}K` : '$0K',
        contractsOverview ? contractsOverview.compensation_rate : '1.0',
    ];

    const handleRowClick = (idIndex) => {
        const contractId = contracts.pending[idIndex].id; // Retrieve the ID from the contracts array
        navigate(`/contracts/${contractId}`);
    };

    return (
        <div className={styles["body"]}>
            <div className={styles["page-header"]}>Dashboard</div>
            <div className={styles["page-segments"]}>
                <Segment title="Overview" segmentBody={<Overview srcs={srcs} titles={titles} values={values} />}></Segment>
                <Segment title="Pending Contracts" segmentBody={<Table headers={headers} types={types} data={pendingData} columnWidths={columnWidths} emptyMessage={"You have no pending contracts..."} onRowClick={handleRowClick}></Table>}></Segment>
            </div>
        </div>
    );
};

// Helper function to map contract data
const mapContractData = (contracts) => {
    return contracts.map((contract) => {
        return [
            contract.code,
            contract.project_name,
            `$${Math.round(contract.coverage / 100 * contract.net_value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
            `${contract.residual_term} Days`,
            contract.status_message,
        ];
    });
};

export default InvestorDashboard;
