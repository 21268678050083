import React from 'react';
import styles from './MeetJoaquim.module.css';
import TopBar from './TopBar';

const MeetJoaquim = () => (
    <div className={styles['app-container']}>
        <TopBar></TopBar>
        <div className={styles.article}>
            <h1 className={styles.title}>Meet Joaquim</h1>
            <p className={styles.content}>
                The article content goes here...
            </p>
            <img src="/images/launch/joaquim.png" alt="Joaquim" className={styles.image} />
        </div>
    </div>

);

export default MeetJoaquim;
