import { collection, doc, onSnapshot } from 'firebase/firestore';
import { auth, db } from '../configs.js';

export const subscribeToAllContracts = (onUpdate) => {
    const currentUser = auth.currentUser;
    const investorDocRef = doc(collection(db, 'users'), currentUser.uid);
    const investmentsCollectionRef = collection(investorDocRef, 'investments');

    // Subscribe to real-time updates
    return onSnapshot(investmentsCollectionRef, (querySnapshot) => {
        const allContracts = [];

        querySnapshot.forEach((doc) => {
            const contractData = doc.data();
            const contract = {
                ...contractData,
                date_created: contractData.date_created.toDate().toISOString(),
                id: doc.id
            };
            allContracts.push(contract);
        });

        onUpdate(allContracts);
    }, (error) => {
        console.error('Error fetching contracts:', error);
    });
};
